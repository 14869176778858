import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { STORE_HOUSE, GET_HOUSES, DESTROY_HOUSE, UPDATE_HOUSE, SET_SELECTED_HOUSE } from '../actionTypes';

export function storeHouse(obj) {
  return function(dispatch) {
    return api
      .storeHouse(obj)
      .then(
        response => dispatch(storeHouseSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function storeHouseSuccess(response) {
  return {
    type: STORE_HOUSE,
    payload: response.data || {}
  }
}

export function getHouses() {
  return function(dispatch) {
    return api
      .getHouses()
      .then(
        response => dispatch(getHousesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getHousesSuccess(response) {
  return {
    type: GET_HOUSES,
    payload: response.data || {}
  }
}

export function destroyHouseById(houseId) {
  return function(dispatch) {
    return api
      .destroyHouseById(houseId)
      .then(
        response => dispatch(destroyHouseSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function destroyHouseSuccess(response) {
  return {
    type: DESTROY_HOUSE,
    payload: response.data || {}
  }
}

export function updateHouse(obj) {
  return function(dispatch) {
    return api
      .updateHouse(obj)
      .then(
        response => dispatch(updateHouseSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateHouseSuccess(response) {
  return {
    type: UPDATE_HOUSE,
    payload: response.data || {}
  }
}

export function setSelectedHouse(houseId) {
  return {
    type: SET_SELECTED_HOUSE,
    payload: houseId
  }
}