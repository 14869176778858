import { GET_ACCOMMODATION_TYPES } from '../actionTypes';

const initialState = []

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_ACCOMMODATION_TYPES:
      return getAccommodationTypes(state, payload);
    default:
      return state;
  }
}

function getAccommodationTypes(state, payload) {
  return [
    ...state,
    ...payload.data,
  ];
}

export default reducer;