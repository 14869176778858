import { GET_APPOINTMENTS, STORE_APPOINTMENT, UPDATE_APPOINTMENT, DESTROY_APPOINTMENT } from '../actionTypes';

const initialState = [];

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_APPOINTMENTS:
      return getAppointments(state, payload);
    case STORE_APPOINTMENT:
      return storeAppointment(state, payload);
    case UPDATE_APPOINTMENT:
      return updateAppointment(state, payload)     
    case DESTROY_APPOINTMENT:
      return destroyAppointment(state, payload);
    default:
      return state;
  }
}

function getAppointments(state, payload) {
  return payload.data;
}

function storeAppointment(state, payload) {
  return [
    ...state,
    payload.data,
  ];
}

function destroyAppointment(state, payload) {
  return state.items.filter(item => item.id != payload.data);
}

function updateAppointment(state, payload) {
  return state.items.map(item => item.id == payload.data.id ? payload.data : item);
}

export default reducer;