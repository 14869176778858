import React from 'react';
import { Page, Card, Row, Col } from 'react-onsenui';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../RenderField';
import { Form } from 'reactstrap';
import RenderToolbar from '../utilities/RenderToolbar';
import Loading from '../utilities/Loading';
import SubmitButton from '../utilities/SubmitButton';

function MovingForm(props) {
  const { popPage, handleSubmit, submitting } = props;

  return (
    <Page renderToolbar={() => <RenderToolbar title="Moving Checklist" defaultBackButton={true} backAction={popPage} />}>
      <Card>
        <h5>Use our moving house checklist to prepare for your upcoming move and assist with a hassle-free moving experience.</h5>
      </Card>
      <Form onSubmit={handleSubmit}>
        <Card>
          <Row>
            <Col width="10%" verticalAlign="center">
              <Field 
                id="power"
                name="power"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Have you got a power provider sorted? <span className="u-text-itallic"> - Our preferred supplier is Nau Mai Rā</span></p>
            </Col>
          </Row>

          <Row>
            <Col width="10%" verticalAlign="center">
              <Field 
                id="internet_phone"
                name="internet_phone"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Have you got internet and/or phone line sorted?</p>
            </Col>
          </Row>
          
          <Row>
            <Col width="10%" verticalAlign="center">
              <Field 
                id="movers"
                name="movers"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Have you organised movers?</p>
            </Col>
          </Row>

          <Row>
            <Col width="10%" verticalAlign="center">
              <Field 
                id="doctor_address_change"
                name="doctor_address_change"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Have you given your family doctor your new address?</p>
            </Col>
          </Row>

          <Row>
            <Col width="10%" verticalAlign="center">
              <Field 
                id="ird_address_change"
                name="ird_address_change"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Have you given the IRD/MSD your new address?</p>
            </Col>
          </Row>

          <Row>
            <Col width="10%" verticalAlign="center">
              <Field 
                id="support_services_address_change"
                name="support_services_address_change"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Have you notified other support services that your address has changed?</p>
            </Col>
          </Row>
        </Card>
        {submitting 
          ? <Loading/>
          : <SubmitButton>Save Changes</SubmitButton>}
      </Form>
    </Page>
  );
}

MovingForm = reduxForm({
  form: 'MovingForm',
  destroyOnUnmount: true
})(MovingForm)

export default MovingForm;