import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'react-onsenui';
import ons from 'onsenui';

function DropzoneField(props) {
  const [files, setFiles] = useState([]);

  const isAndroid = ons.platform.isAndroid();

  const {getRootProps, getInputProps} = useDropzone({
    accept: props.accept || 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      props.onDrop(acceptedFiles);
    }
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className={"dropzone" + (props.className ? ` ${props.className}` : '')}>
      <div {...getRootProps()}>
        <input {...getInputProps()}/>
        <a className={isAndroid ? 'button--material button--large--cta' : 'button--large--cta'}>
          {props.buttonText || 'Browse or take a photo'}
          <Icon className="u-ml-05" size={30} icon={props.icon ? props.icon : "plus" } color="white"/>
        </a>
      </div>
    </section>
  )
}

export default DropzoneField;