import React, { Component } from 'react';
import { Page, Toolbar, BackButton, AlertDialog, Button, ProgressCircular, Card } from 'react-onsenui';
import ResetPasswordConfirmForm from './ResetPasswordConfirmForm';
import * as UserActions from '../../actions/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AJAX_CALL_ERROR, LOGIN, PASSWORD_RESET, PASSWORD_RESET_CONFIRM } from '../../actionTypes';
import { SubmissionError } from 'redux-form';

class ResetPasswordConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
    }
  }

  handleDialogClick = () => {
    const { sent } = this.state;
    const { popPage } = this.props;

    this.setState({isOpen: false});

    if (sent) {
      popPage();
    }
  }

  handleSubmit = values => {
    const { actions } = this.props;

    const aToken = window.location.pathname.split('/');
    const sToken = aToken[aToken.length-1];

    return actions.passwordResetConfirm({
      email: values.email,
      token: sToken,
      password: values.password,
      password_confirmation: values.confirm_password,
    }).then(response => {
      if (response.type === PASSWORD_RESET_CONFIRM) {
        return actions.login({
          email: values.email,
          password: values.password
        }).then(response => {
          console.log(response);
          
          if (response.type === LOGIN) {
            window.location.replace('/');
          } else if (response.type === AJAX_CALL_ERROR) {
            throw new SubmissionError({
              _error: response.payload.message
            });
          } else {
            this.setState({loginFailed: true});
          }
        });
      } else if (response.type === AJAX_CALL_ERROR) {
        throw new SubmissionError({
          _error: response.payload.response.data.message,
        });
      } else {
        this.setState({message: "There was an error resetting your password"});
      }
    });
  }

  renderToolbar = () => {
    const { popPage } = this.props;

    return (
      <Toolbar>
        {/* <div className="left">
          <BackButton onClick={popPage}>
            Back
          </BackButton>
        </div> */}
        <div className="center">
          Reset Password
        </div>
      </Toolbar>
    );
  }

  render() {
    const { submitting } =  this.props;
    const { isOpen, title, content, sent } = this.state;
    
    return (
      <Page renderToolbar={this.renderToolbar}>
        <Card>
          {submitting
            ? <div className="u-text-center"><ProgressCircular indeterminate/></div>
            : <ResetPasswordConfirmForm onSubmit={this.handleSubmit}/>
          }
        </Card>
        <AlertDialog isOpen={isOpen}>
          <div className="alert-dialog-title">{title}</div>
          <div className="alert-dialog-content">{content}</div>
          <div className="alert-dialog-footer">
            <Button 
              onClick={() => this.handleDialogClick(sent)} 
              className="alert-dialog-button"
            >
              Ok
            </Button>
          </div>
        </AlertDialog>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm);