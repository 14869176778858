import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { 
  GET_HEATING_TYPES,
  STORE_HEATING_TYPE,
  UPDATE_HEATING_TYPE,
  DESTROY_HEATING_TYPE
} from '../actionTypes';

export function getHeatingTypes() {
  return function(dispatch) {
    return api
      .getHeatingTypes()
      .then(
        response => dispatch(getHeatingTypesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function getHeatingTypesSuccess(response) {
  return {
    type: GET_HEATING_TYPES,
    payload: response.data || {}
  }
}

export function storeHeatingType(obj) {
  return function(dispatch) {
    return api
      .storeHeatingType(obj)
      .then(
        response => dispatch(storeHeatingTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function storeHeatingTypeSuccess(response) {
  return {
    type: STORE_HEATING_TYPE,
    payload: response.data || {}
  }
}

export function updateHeatingType(obj) {
  return function(dispatch) {
    return api
      .updateHeatingType(obj)
      .then(
        response => dispatch(updateHeatingTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function updateHeatingTypeSuccess(response) {
  return {
    type: UPDATE_HEATING_TYPE,
    payload: response.data || {}
  }
}

export function destroyHeatingType(id) {
  return function(dispatch) {
    return api
      .destroyHeatingType(id)
      .then(
        response => dispatch(destroyHeatingTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function destroyHeatingTypeSuccess(response) {
  return {
    type: DESTROY_HEATING_TYPE,
    payload: response.data || {}
  }
}