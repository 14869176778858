import {
  AUTH_CHECK,
  GET_AUTH_USER,
  LOGIN,
  LOGOUT,
  REGISTER,
  PASSWORD_RESET,
  UPDATE_USER_MOVING_CHECKLIST,
  PASSWORD_RESET_CONFIRM,
  UPDATE_USER_APPOINTMENT_AVAILABILITY,
  UPDATE_USER_ACCOMODATION_DETAILS
} from '../actionTypes';

import * as api from '../api/api';
import ajaxCallError from './ajaxCallError';

export function authCheck() {
  return function(dispatch) {
    return new Promise(resolve => {
      const data = {
        type: AUTH_CHECK,
        payload: !!localStorage.getItem('access_token')
      };

      dispatch(data);
      resolve(data);
    });
  }
}

export function getAuthUser() {
  return function(dispatch) {
    return api
      .getAuthUser()
      .then(
        response => dispatch(getAuthUserSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getAuthUserSuccess(response) {
  return {
    type: GET_AUTH_USER,
    payload: response.data || {}
  }
}

export function getUser(userID) {
  return function(dispatch) {
    return api
      .getUser(userID)
      .then(
        response =>  dispatch(getUserSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getUserSuccess(response) {
  return {
    type: GET_AUTH_USER,
    payload: response.data || {}
  }
}

export function login(obj) {
  return function(dispatch) {
    return api
      .login(obj)
      .then(
        response =>  dispatch(loginSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function loginSuccess(response) {
  return {
    type: LOGIN,
    payload: response.data || {}
  }
}

export function register(obj) {
  return function(dispatch) {
    return api
      .register(obj)
      .then(
        response =>  dispatch(registerSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function registerSuccess(response) {
  return {
    type: REGISTER,
    payload: response.data || {}
  }
}

export function logout() {
  return function(dispatch) {
    return new Promise(resolve => {
      const data = {
        type: LOGOUT,
      };

      dispatch(data);
      resolve(data);
    });
  }
}

export function passwordReset(obj) {
  return function(dispatch) {
    return api
      .passwordReset(obj)
      .then(
        response => dispatch(passwordResetSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function passwordResetSuccess(response) {
  return {
    type: PASSWORD_RESET,
    payload: response.data || {}
  }
}

export function passwordResetConfirm(obj) {
  return function(dispatch) {
    return api
      .passwordResetConfirm(obj)
      .then(
        response => dispatch(passwordResetConfirmSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function passwordResetConfirmSuccess(response) {
  return {
    type: PASSWORD_RESET_CONFIRM,
    payload: response.data || {}
  }
}

export function updateUserMovingChecklist(obj) {
  return function(dispatch) {
    return api
      .updateUserMovingChecklist(obj)
      .then(
        response => dispatch(updateUserMovingChecklistSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function updateAuthUser(obj) {
  return function(dispatch) {
    return api
      .updateUser(obj)
      .then(
        response => dispatch(updateAuthUserSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateAuthUserSuccess(response) {
  return {
    type: UPDATE_USER_ACCOMODATION_DETAILS,
    payload: response.data || {}
  }
}



function updateUserMovingChecklistSuccess(response) {
  return {
    type: UPDATE_USER_MOVING_CHECKLIST,
    payload: response.data || {}
  }
}

export function updateUserAppointmentAvailability(obj) {
  return function(dispatch) {
    return api
      .updateUserAppointmentAvailability(obj)
      .then(
        response => dispatch(updateUserAppointmentAvailabilitySuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateUserAppointmentAvailabilitySuccess(response) {
  return {
    type: UPDATE_USER_APPOINTMENT_AVAILABILITY,
    payload: response.data || {}
  }
}

export function updateUserAccomodationDetails(obj) {
  return function(dispatch) {
    return api
      .updateUserAccomodationDetails(obj)
      .then(
        response => dispatch(updateUserAccomodationDetailsSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateUserAccomodationDetailsSuccess(response) {
  return {
    type: UPDATE_USER_ACCOMODATION_DETAILS,
    payload: response.data || {}
  }
}

