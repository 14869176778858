import * as api from '../api/api';
import ajaxCallError from './ajaxCallError';
import { 
  GET_USERS, 
  UPDATE_USER,
  SET_USERS_CURRENT_PAGE, 
  SET_USERS_SEARCH_TERM, 
} from '../actionTypes';

export function getUsers() {
  return function(dispatch) {
    return api
      .getUsers()
      .then(
          response => dispatch(getUsersSuccess(response)),
          error => dispatch(ajaxCallError(error))
      );
  }
}

function getUsersSuccess(response) {
  return {
    type: GET_USERS,
    payload: response.data || {}
  }
}

export function updateUser(userID) {
  return function(dispatch) {
    return api
      .updateUser(userID)
      .then(
        response =>  dispatch(updateUserSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateUserSuccess(response) {
  return {
    type: UPDATE_USER,
    payload: response.data || {}
  }
}


export function setUsersPage(num) {
  return {
    type: SET_USERS_CURRENT_PAGE,
    payload: num
  }
}

export function setUsersSearchTerm(searhTerm) {
  return {
    type: SET_USERS_SEARCH_TERM,
    payload: searhTerm
  }
}