import React, { Component } from 'react';
import { Page, Card, ProgressCircular, ListItem, List, Button } from "react-onsenui";
import { connect } from "react-redux";
import Checklist from './checklist/Checklist';
import RenderToolbar from './utilities/RenderToolbar';
import UsefulLinks from './checklist/UsefulLinks';
import LinkIcon from '@material-ui/icons/Link';
import UserForm from './users/UserForm';
import AccomodationDetailsForm from './users/AccomodationDetailsForm';


class Profile extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      submitting: false
    }
  }

  renderToolbar = () => <RenderToolbar title="My Profile" defaultBackButton={true}/>

  renderRow = (row, i) => {
    const { navigator } = this.props;

    return (
      <ListItem 
        key={i}
        modifier="divider"
        onClick={() => navigator.pushPage({Component: row.component, props: row.props})}
      >
        <row.icon/>
        <p className="u-ml-05">{row.title}</p>
      </ListItem>
    );
  }

  handleEditClicked = () => {
    const { navigator, user } = this.props;

    navigator.pushPage(
      { 
        Component: UserForm,
        props: {
          initialValues: { 
            ...user, 
            admin: user.roles.filter(r => r.pivot.role_id === 3).length > 0, 
            bookable: user.roles.filter(r => r.pivot.role_id === 2).length > 0
          },
          popPage: () => navigator.popPage()
        }
      }
    );
  }

  handleEditAccomodationDetailsClicked = () => {
    const { navigator, user } = this.props;

    navigator.pushPage(
      { 
        Component: AccomodationDetailsForm,
        props: {
          initialValues: { 
            ...user, 
            admin: user.roles.filter(r => r.pivot.role_id === 3).length > 0, 
            bookable: user.roles.filter(r => r.pivot.role_id === 2).length > 0
          },
          popPage: () => navigator.popPage()
        }
      }
    );
  }

  render() {
    const { user, navigator } = this.props

    return (
      <Page renderToolbar={this.renderToolbar}>
        {user.email ? (
          <>
            <Button 
              className="button--large u-mb-1"
              onClick={this.handleEditClicked}
            >
              Edit Profile
            </Button>
            <Card>
              <div className="c-profile">
                {user.image && <img className="img-fluid" src={user.image}/>}
                <h3>My Details</h3>
                <p className="c-profile__subheading">Full Name</p>
                <p className="c-profile__item">{user.first_name + ' ' + user.last_name}</p>
                <p className="c-profile__subheading">Email</p>
                <p className="c-profile__item">{user.email}</p>
                {!user.admin && (
                  <>
                    <p className="c-profile__subheading">Age</p>
                    <p className="c-profile__item">{user.age}</p>
                    <p className="c-profile__subheading">Gender</p>
                    <p className="c-profile__item">{user.gender}</p>
                    <p className="c-profile__subheading">Ethnicity</p>
                    <p className="c-profile__item">{user.ethnicity}</p>
                    <p className="c-profile__subheading">Phone</p>
                    <p className="c-profile__item">{user.phone}</p>

                    <Button 
                      className="button--large u-mb-1 u-mt-1"
                      onClick={this.handleEditAccomodationDetailsClicked}
                    >
                      Update Housing Status
                    </Button>

                    {/* <p className="c-profile__subheading">Accomodation Type</p> */}

                    {/* {user.accommodation_type && <p className="c-profile__item">{user.accommodation_type.name}</p>}
                      {user.address ? (
                        <>
                          <p className="c-profile__subheading">Address</p>
                          <p className="c-profile__item">{user.address}</p>
                        </>
                      ) : (
                        user.accommodation_site && (
                          <>
                            <p className="c-profile__subheading">Address</p>
                            <p className="c-profile__item">{user.accommodation_site.address}</p>
                          </>
                        )
                      )} */}
                  </>
                )}
              </div>

              {user.admin ?
                <List
                  className="u-mt-1"
                  dataSource={[
                    {
                      title: 'Useful Links',
                      component: UsefulLinks,
                      icon: LinkIcon,
                    }
                  ]}
                  renderRow={this.renderRow}
                />
              : null}
            </Card>

            {!user.admin && <Checklist navigator={navigator}/>}
          </>
        ) : (
          <div className="page-loading">
            <ProgressCircular indeterminate />
          </div>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Profile);
