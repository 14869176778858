import React, { Component } from 'react';
import { Page, Card, List, Button, Row, Col, Icon } from 'react-onsenui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AccommodationSiteActions from '../actions/accommodationSite';
import AccommodationSitesForm from './accomodation-sites/AccommodationSitesForm';
import AccomodationSite from './accomodation-sites/AccommodationSite';
import { url } from '../utils/s3';


class Covid extends Component {

  componentDidMount() {
    const { actions } = this.props;

    actions.getAccommodationSites().then(response => {
      console.log(response);
    });
  }

  handleAddAccomodationSiteClicked = () => {
    const { pushPage, actions } = this.props;

    const props = {
      title: 'Add Accommodation Site',
      buttonText: 'Add This Site',
      formAction: actions.storeAccommodationSite,
      initialValues: { documents: [] }
    }

    pushPage(AccommodationSitesForm, 'AccommodationSitesForm', props);
  }

  handleAccommodationSiteClicked = id => {
    const { pushPage, actions } = this.props;

    actions.setSelectedAccommodationSite(id)

    pushPage(AccomodationSite, 'AccomodationSite');
  }


  renderRow = (item, i) => (
    <div key={i} className="c-listing c-accommodation-site-item" onClick={() => this.handleAccommodationSiteClicked(item.id)}>
      <Card>
        <Row>
          <Col width="80%">
            <h2 className="u-mt-25">{item.name}</h2>                     
            <span>{item.address}</span>
          </Col>
          <Col width="20%">
            <div className="c-accommodation-site-item__icon">
              <Icon 
                icon="ion-ios-document" 
                className="u-mr-05"
                size={{default: 26, material: 26}}
              />
              <span>{item.documents.length.toString()}</span>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )

  render() {
    const { accommodationSites, isAdmin, user } = this.props;

    return (
      <Page>
        {isAdmin ? (
          <>
            <Button 
              className="button--large--cta u-mb-1"
              onClick={this.handleAddAccomodationSiteClicked}
            >
              Add Accomodation Site
            </Button>

            {accommodationSites.length > 0 ? (
              <List 
                dataSource={accommodationSites}
                renderRow={this.renderRow}
              />
            ) : (
              <Card>
                <p className="u-text-center">No accomodation sites found</p>
              </Card>
            )}
          </>
        ) : (
          <>
            {user.accommodation_site ? (
              <Card>
                <div className="c-accommodation-site">
                  <Row>
                    <h3>{user.accommodation_site.name}</h3>
                  </Row>
                  <Row>
                    <span>{user.accommodation_site.address}</span>
                  </Row>
                  {user.accommodation_site.info && (
                    <>
                      <h4 className="u-mt-1">Information</h4>
                      <span>{user.accommodation_site.info}</span>
                    </>
                  )}
                  {user.accommodation_site.documents && (
                    <Row>
                      <h4 className="u-mb-05">Documents</h4>
                      
                      <p class="u-mt-0">Check the documents below for information about {user.accommodation_site.name}'s covid protocols</p>

                      {user.accommodation_site.documents.map(document => (
                        <div className="c-accommodation-site__document u-mb-05">
                          <Icon 
                            icon="ion-ios-document" 
                            className="u-mr-05"
                            size={{default: 26, material: 26}}
                          />
                          <a href={url(document.file)} target="_blank" rel="noopener noreferrer">{document.file}</a>
                        </div>
                      ))}
                    </Row>
                  )}
                </div>
              </Card>
            ) : (
              <Card>
                <p className="u-text-center">No information to display for your location.</p>
              </Card>
            )}
          </>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    accommodationSites: state.accommodationSites.items,
    user: state.user,
    isAdmin: state.user.admin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...AccommodationSiteActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Covid);