import React from 'react';
import { Button } from 'react-onsenui';

export default function FormPhoto(props) {
  const { selected, photo, primary, onClick, onRemovePhotoClicked, onSetMainClicked } = props;

  return (
    <div className="c-form__photo" onClick={onClick}>
      {selected && (
        <div className="c-form__photo__controls">
          <div className="c-form__photo__controls__buttons">
            {onSetMainClicked && (
            <Button 
               onClick={onSetMainClicked} 
               className={primary ? 'button--large--cta u-bg--green' : 'button--large--cta'} 
             >
               Main
             </Button>
            )}
            <Button 
              onClick={onRemovePhotoClicked} 
              className={"button--large--cta u-bg--red"}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
      <img className={selected ? 'c-form__photo__image c-form__photo__image__selected' : 'c-form__photo__image'} src={`https://st-wact.s3.amazonaws.com/${photo}`} />
    </div>
  )
}