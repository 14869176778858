import React from 'react';
import { Field } from 'redux-form';
import { renderField } from '../../RenderField';
import * as Validators from '../../Validators';

export function StepFour(props) {
  const { accomodationVal, accommodationTypes, accommodationSites, errors } = props;
  
  return (
    <div>
      <h2>Tell us about your situation</h2>
      <p>What type of accomodation do you currently have?</p>
      <div className="c-form__row">
        <Field 
          name="accommodation_type_id"
          id="accommodation_type_id"
          component={renderField} 
          type="select"
          options={accommodationTypes}
          placeholder="Accomodation Type"
          validate={Validators.required}
        />
      </div>

      {accomodationVal == 6 && (
        <>
          <p>Tell us more about your accomodation</p>
          <div className="c-form__row">
            <Field 
              name="accommodation_other"
              id="accommodation_other" 
              component={renderField} 
              type="textarea"
              placeholder="Accomodation details"
              validate={Validators.required}
            />
            {errors.accommodation_other !== undefined && <div className="c-form__error"><i>{errors.accommodation_other[0]}</i></div>}
          </div>
        </>
      )}

      {accomodationVal != 5 && (
        <>
          <p className={accomodationVal == 6 ? 'u-mt-70px' : ''}>What is the address of your accomodation?</p>

          {accomodationVal == 7 ? (
            <div className="c-form__row">
              <Field 
                name="accommodation_site_id"
                id="accommodation_site_id"
                component={renderField} 
                type="select"
                options={accommodationSites}
                placeholder="Please select"
                validate={Validators.required}
              />
            </div>
          ) : (
            <>
              <div className="c-form__row">
                <Field 
                  name="address"
                  id="address" 
                  component={renderField} 
                  type="text" 
                  placeholder="Address"
                  validate={Validators.required}
                />
                {errors.address !== undefined && <div className="c-form__error"><i>{errors.address[0]}</i></div>}
              </div>
            </>
          )}
         
        </>
      )}
    </div>
  );
}