import React, { Component } from 'react';
import { Page, Toolbar, BackButton, AlertDialog, Button, ProgressCircular, Card } from 'react-onsenui';
import ResetPasswordForm from './ResetPasswordForm';
import * as UserActions from '../../actions/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PASSWORD_RESET } from '../../actionTypes';


class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      title: '',
      content: '',
      sent: false,
    }
  }

  handleDialogClick = () => {
    const { sent } = this.state;
    const { navigator } = this.props;

    this.setState({isOpen: false});

    if (sent) {
      navigator.popPage();
    }
  }

  handleSubmit = values => {
    const { actions } = this.props;

    return actions.passwordReset({email: values.email}).then(response => {
      console.log(response);

      let title = 'Email not found';
      let content = 'We could not find an account with that email address in our database, please check the address you entered is correct and try again';
      let sent = false;

      if (response.type === PASSWORD_RESET) {
        title = 'Check your inbox';
        content = 'An email has been sent to ' + values.email + ' please check your inbox';
        sent = true;
      }

      this.setState({
        isOpen: true,
        title: title,
        content: content,
        sent: sent,
      });
    });
  }

  renderToolbar = () => {
    const { popPage } = this.props;

    return (
      <Toolbar>
        <div className="left">
          <BackButton onClick={popPage}>
            Back
          </BackButton>
        </div>
        <div className="center">
          Reset Password
        </div>
      </Toolbar>
    );
  }

  render() {
    const { submitting } =  this.props;
    const { isOpen, title, content, sent } = this.state;
    
    return (
      <Page renderToolbar={this.renderToolbar}>
        <Card>
          <p>Please enter your e-mail address and we will send you a link with which you can reset your password</p>
          {submitting
            ? <div className="u-text-center"><ProgressCircular indeterminate/></div>
            : <ResetPasswordForm onSubmit={this.handleSubmit}/>
          }
        </Card>
        <AlertDialog isOpen={isOpen}>
          <div className="alert-dialog-title">{title}</div>
          <div className="alert-dialog-content">{content}</div>
          <div className="alert-dialog-footer">
            <Button 
              onClick={() => this.handleDialogClick(sent)} 
              className="alert-dialog-button"
            >
              Ok
            </Button>
          </div>
        </AlertDialog>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);