import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Form, Field, formValueSelector } from 'redux-form';
import * as UsersActions from '../../actions/users';
import * as UserActions from '../../actions/user';
import RenderToolbar from '../utilities/RenderToolbar';
import { Page, Card, Row, Col } from 'react-onsenui';
import { renderField } from '../RenderField';
import SubmitButton from '../utilities/SubmitButton';
import * as Validators from '../Validators';
import Loading from '../utilities/Loading';


function UserForm(props) {
  const { popPage, actions, submitting, handleSubmit, user, admin } = props;

  const handleUserSubmit = values => {
    if (user.id === values.id) {
      return actions.updateAuthUser(values).then(response => {
        console.log(response);
  
        popPage();
      });
    } else {
      return actions.updateUser(values).then(response => {
        console.log(response);
  
        popPage();
      });
    }
  }

  const renderToolbar = () => (
    <RenderToolbar 
      title="Edit User Details"
      backAction={popPage}
    />
  )

  return (
    <Page renderToolbar={renderToolbar}>
      <Form onSubmit={handleSubmit(handleUserSubmit)} className="c-form">
        <Card>
          <p className="c-form__label">First Name</p>
          <div className="c-form__row">
            <Field 
              id="first_name"
              name="first_name"
              type="text"
              placeholder="First Name"
              component={renderField}
              validate={Validators.required}
            />
          </div>

          <p className="c-form__label">Last Name</p>
          <div className="c-form__row">
            <Field 
              id="last_name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              component={renderField}
              validate={Validators.required}
            />
          </div>

          <p className="c-form__label">Age</p>
          <div className="c-form__row">
            <Field 
              id="age"
              name="age"
              type="number"
              placeholder="Age"
              component={renderField}
              validate={Validators.required}
            />
          </div>

          <p className="c-form__label">Gender</p>
          <div className="c-form__row">
            <Field 
              name="gender"
              id="gender" 
              component={renderField} 
              type="select" 
              placeholder="Gender"
              options={[
                {id: 'Male', name: 'Male'},
                {id: 'Female', name: 'Female'},
                {id: 'Gender Diverse', name: 'Gender Diverse'},
              ]}
              validate={[Validators.required]}
            />
          </div>

          <p className="c-form__label">Ethnicity</p>
          <div className="c-form__row">
            <Field 
              name="ethnicity"
              id="ethnicity" 
              component={renderField} 
              type="select" 
              placeholder="Ethnicity"
              options={[
                {id: 'Maori', name: 'Maori'},
                {id: 'NZ European', name: 'NZ European'},
                {id: 'Pacific', name: 'Pacific Islander'},
              ]}
              validate={Validators.required}
            />
          </div>

          <p className="c-form__label">Phone</p>
          <div className="c-form__row">
            <Field 
              id="phone"
              name="phone"
              type="text"
              placeholder="Phone"
              component={renderField}
              validate={Validators.required}
            />
          </div>

          {/* <p className="c-form__label">Accomodation</p>
          <div className="c-form__row">
            <Field 
              id="accommodation_type_id"
              name="accommodation_type_id"
              type="select"
              options={accommodationTypes}
              component={renderField}
              validate={Validators.required}
            />
          </div>

          {selectedType === 6 && (
            <>
              <p className="c-form__label">Details</p>
              <div className="c-form__row">
                <Field 
                  name="accommodation_other"
                  id="accommodation_other" 
                  component={renderField} 
                  type="textarea"
                  placeholder="Accomodation details"
                  validate={Validators.required}
                />
              </div>
            </>
          )}

          {selectedType !== 5 && (
            <>
              <p className="c-form__label">Address</p>
              <div className={selectedType === 6 ? 'c-form__row u-mt-70px' : 'c-form__row'}>
                <Field
                  id="address"
                  name="address"
                  type="text"
                  placeholder="Address"
                  component={renderField}
                />
              </div>
            </>
          )} */}
      
          {admin && (
            <>
              <Row>
                <Col width="10%" verticalAlign="center">
                  <Field
                    id="admin"
                    name="admin"
                    type="checkbox"
                    label="Is Admin"
                    component={renderField}
                  />
                </Col>
                <Col>
                  <p>Is Admin</p>
                </Col>
              </Row>

              <Row>
                <Col width="10%" verticalAlign="center">
                  <Field
                    id="bookable"
                    name="bookable"
                    type="checkbox"
                    label="Is Bookable"
                    component={renderField}
                  />
                </Col>
                <Col>
                  <p>Is Bookable</p>
                </Col>
              </Row>
            </>
          )}
        </Card>

        {submitting 
          ? <Loading/>
          : <SubmitButton>Save Changes</SubmitButton>}
      </Form>
    </Page>
  )
}

UserForm = reduxForm({
  form: 'UserForm',
  enableReinitialize: true
})(UserForm)

const selector = formValueSelector('UserForm');

function mapStateToProps(state) {
  return {
    accommodationTypes: state.accommodationTypes,
    selectedType: selector(state, 'accommodation_type_id'),
    admin: selector(state, 'admin'),
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UsersActions, ...UserActions }, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)