import apiClient from "./apiClient";

// User
export function getAuthUser() {
  return apiClient.get(`/user`);
}

export function getUser(id) {
  return apiClient.get(`/user/${id}`);
}

export function login(obj) {
  return apiClient.post(`/auth/login`, obj);
}

export function updateUser(obj) {
  return apiClient.post(`/user/${obj.id}`, obj);
}

export function register(obj) {
  return apiClient.post(`/auth/register`, obj);
}

export function passwordReset(obj) {
  return apiClient.post(`/auth/password_reset`, obj);
}

export function passwordResetConfirm(obj) {
  return apiClient.post(`/auth/password_reset/confirm`, obj);
}

export function updateUserMovingChecklist(obj) {
  return apiClient.post(`/user/${obj.user_id}/moving_checklist`, obj);
}

export function updateUserAppointmentAvailability(obj) {
  return apiClient.post(`/user/${obj.user_id}/appointment_availability`, obj);
}

export function updateUserAccomodationDetails(obj) {
  return apiClient.post(`/user/${obj.id}/accomodation_details`, obj);
}

// Users
export function getUsers() {
  return apiClient.get(`/users`);
}

// Appointments
export function getAppointments() {
  return apiClient.get(`/appointments`);
}

export function storeAppointment(obj) {
  return apiClient.post(`/appointments`, obj);
}

export function destroyAppointmentById(id) {
  return apiClient.delete(`/appointments/${id}`);
}

export function updateAppointment(obj) {
  return apiClient.put(`/appointments/${obj.id}`, obj)
}


// Houses
export function storeHouse(obj) {
  return apiClient.post(`/houses`, obj);
}

export function getHouses() {
  return apiClient.get('/houses');
}

export function destroyHouseById(houseId) {
  return apiClient.delete(`/houses/${houseId}`);
}

export function updateHouse(obj) {
  return apiClient.put(`/houses/${obj.id}`, obj)
}

// Services
export function storeService(obj) {
  return apiClient.post(`/services`, obj);
}

export function getServices() {
  return apiClient.get('/services');
}

export function destroyServiceById(serviceId) {
  return apiClient.delete(`/services/${serviceId}`);
}

export function updateService(obj) {
  return apiClient.put(`/services/${obj.id}`, obj);
}

// Service Types
export function getServiceTypes() {
  return apiClient.get('/service_types');
}

export function storeServiceType(obj) {
  return apiClient.post('/service_types', obj);
}

export function updateServiceType(obj) {
  return apiClient.put(`/service_types/${obj.id}`, obj);
}

export function destroyServiceType(id) {
  return apiClient.delete(`/service_types/${id}`);
}

// Heating Types
export function getHeatingTypes() {
  return apiClient.get('/heating_types');
}

export function storeHeatingType(obj) {
  return apiClient.post('/heating_types', obj);
}

export function updateHeatingType(obj) {
  return apiClient.put(`/heating_types/${obj.id}`, obj);
}

export function destroyHeatingType(id) {
  return apiClient.delete(`/heating_types/${id}`);
}

// Building Types
export function getBuildingTypes() {
  return apiClient.get('/building_types');
}

export function storeBuildingType(obj) {
  return apiClient.post('/building_types', obj);
}

export function updateBuildingType(obj) {
  return apiClient.put(`/building_types/${obj.id}`, obj);
}

export function destroyBuildingType(id) {
  return apiClient.delete(`/building_types/${id}`);
}

// Accomodation Types
export function getAccommodationTypes() {
  return apiClient.get('/accommodation_types');
}

// Applications
export function storeApplication(obj) {
  return apiClient.post(`/applications`, obj);
}

// Reports
export function getAccomodationTypeReport() {
  return apiClient.get('/reports/accommodation_type');
}

// Accomodation Sites
export function getAccommodationSites() {
  return apiClient.get('/accommodation_sites');
}

export function storeAccommodationSite(obj) {
  return apiClient.post('/accommodation_sites', obj);
}

export function updateAccommodationSite(obj) {
  return apiClient.put(`/accommodation_sites/${obj.id}`, obj);
}

export function destroyAccommodationSite(id) {
  return apiClient.delete(`/accommodation_sites/${id}`);
}