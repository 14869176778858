import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectPersonalDocuments, selectReferenceDocuments } from '../../reducers/user';
import DocumentsForm from './DocumentsForm';
import MovingForm from './MovingForm';
import ReferencesForm from './ReferencesForm';
import { Card, ProgressBar, List, ListItem } from "react-onsenui";
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';
import cogoToast from 'cogo-toast';
import UsefulLinks from './UsefulLinks';


class Checklist extends Component {
  handleUpdateChecklist = values => {
    const { actions, user } = this.props;

    return actions.updateUserMovingChecklist({...values, user_id: user.id}).then(response => {
      console.log(response);

      if (response.payload.message === 'success') {
        cogoToast.success('Your moving check list has been updated');
      }
    });
  }

  renderRow = (row, i) => {
    const { navigator } = this.props;

    return (
      <ListItem 
        key={i}
        modifier="divider"
        onClick={() => navigator.pushPage({Component: row.component, props: row.props})}
      >
        <row.icon/>
        <p className="u-ml-05">{row.title}</p>
      </ListItem>
    );
  }

  render() {
    const { personalDocs, referenceDocs, user } = this.props;

    let progressValue = 0;

    const requiredItems = [
      'photo_id', 
      'credit_check', 
      'rental_reference_one',
      'rental_reference_two',
      'rental_reference_three',
      'character_reference',
      'power',
      'internet_phone',
      'movers',
      'doctor_address_change',
      'ird_address_change',
      'support_services_address_change'
    ];

    if (user.moving_checklist) {
      requiredItems.forEach(item => {
        if (user.moving_checklist[item]) {
          progressValue += 8.33;
        } 
      });
    }

    return (
      <Card className="c-profile-checklist">
        <h3>My Checklist</h3>

        <ProgressBar value={progressValue} className="u-mb-1 u-mt-1"/>

        <DocumentsForm 
          initialValues={{
            user_id: user.id,
            ...personalDocs
          }} 
          onSubmit={this.handleUpdateChecklist}
        />

        <List 
          className="u-mt-1"
          dataSource={[
            {
              title: 'References',  
              component: ReferencesForm,
              icon: DescriptionIcon,
              props: {
                initialValues: {
                  user_id: user.id,
                  ...referenceDocs
                }, 
                onSubmit: this.handleUpdateChecklist
              }
            },
            {
              title: 'Moving',     
              component: MovingForm,
              icon: LocalShippingIcon,   
              props: {
                initialValues: user.moving_checklist, 
                onSubmit: this.handleUpdateChecklist
              }
            },
            {
              title: 'Useful Links',
              component: UsefulLinks,
              icon: LinkIcon,
            }
          ]}
          renderRow={this.renderRow}
        />
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    personalDocs: selectPersonalDocuments(state),
    referenceDocs: selectReferenceDocuments(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist)