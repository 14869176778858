import React from 'react';
import { Page, Card, List, ListItem, Button, Icon } from 'react-onsenui';
import RenderToolbar from '../utilities/RenderToolbar';
import { connect } from 'react-redux';
import HeatingTypeForm from './HeatingTypeForm';
import { bindActionCreators } from 'redux';
import * as HeatingTypeActions from '../../actions/heatingType';


function HeatingTypes({ heatingTypes, navigator, actions }) {

  const handleAddHeatingTypeClicked = () => {
    navigator.pushPage({ 
      Component: HeatingTypeForm,
      props: {
        formAction: actions.storeHeatingType
      }
    });
  }

  const handleHeatingTypeClicked = heatingType => {
    navigator.pushPage({ 
      Component: HeatingTypeForm,
      props: { 
        initialValues: heatingType,
        formAction: actions.updateHeatingType
      }
    });
  }

  const handleDeleteClicked = id => {
    const response = window.confirm('Are you sure you want to delete this heating type?');
    if (response) {
      actions.destroyHeatingType(id).then(response => {
        console.log(response);
      });
    }
  }

  const renderRow = (heatingType, i) => (
    <ListItem key={i}> 
      <div className="u-flex-row-between">
        <span className="u-w-50">{heatingType.name}</span>

        <div>
          <Button className="u-mr-1" onClick={() => handleHeatingTypeClicked(heatingType)}>
            <Icon icon="edit"/>
          </Button>

          <Button onClick={() => handleDeleteClicked(heatingType.id)}>
            <Icon icon="trash"/>
          </Button>
        </div>
      </div>
    </ListItem>
  );

  const renderToolbar = () => <RenderToolbar title="Building Types" defaultBackButton={true}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Button 
        onClick={handleAddHeatingTypeClicked}
        className="button--large--cta u-mb-1"
      >
        Add Heating Type
      </Button>

      <Card>
        <h3>Heating Types</h3>
        
        <List
          dataSource={heatingTypes}
          renderRow={renderRow}
        />
      </Card>
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    heatingTypes: state.heatingTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...HeatingTypeActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeatingTypes);
