import React, { Component } from 'react';
import { Page, BackButton, Toolbar } from "react-onsenui";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import RegisterForm from './RegisterForm';
import { AJAX_CALL_ERROR, LOGIN, REGISTER } from '../../actionTypes';
import cogoToast from 'cogo-toast';


class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      errors: [],
    }
  }

  handleSubmit = values => {
    const { actions, navigator } = this.props;
    
    return actions.register(values).then(response => {
      console.log(response);
      
      if (response.type === REGISTER) {
        actions.login({email: values.email, password: values.password}).then(response => {
          console.log(response);

          if (response.type === LOGIN) {
            actions.getAuthUser().then(response => {
              console.log(response);

              navigator.popPage();
            });
          }
        });
        
      } else if (response.type === AJAX_CALL_ERROR) {        
        const errors = response.payload.response.data.errors;

        console.log(errors)

        if (errors) {
          let step = 4;
        
          switch (true) {
            case errors.first_name !== undefined:
              cogoToast.error(errors.first_name[0]);
              step = 0;
              break;
            case errors.last_name !== undefined:
              cogoToast.error(errors.last_name[0]);
              step = 0;
              break;
            case errors.age !== undefined:
              cogoToast.error(errors.age[0]);
              step = 1;
              break;
            case errors.email !== undefined:
              cogoToast.error(errors.email[0]);
              step = 2;
              break;
            case errors.phone !== undefined:
              cogoToast.error(errors.phone[0]);
              step = 2;
              break;
            case errors.accommodation_other !== undefined:
              cogoToast.error(errors.accommodation_other[0]);
              step = 3;
              break;
            case errors.address !== undefined:
              cogoToast.error(errors.address[0]);
              step = 3;
              break;
            case errors.password !== undefined:
              cogoToast.error(errors.password[0]);
              step = 4;
              break;
          }

          this.setState({errors, step});
        } else {
          cogoToast.error('Somthing went wrong, please check your network connection and try again');
        }

      }
    });
  }
  
  renderToolbar = () => {
    return (
      <Toolbar>
        <div className="left">
          <BackButton>Back</BackButton>
        </div>
        <div className="center">
          Register
        </div>
      </Toolbar>
    );
  }

  handleNextClicked = () => this.setState({step: this.state.step+1});
  handlePreviousClicked = () => this.setState({step: this.state.step-1});

  render() {
    const { step, errors } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <RegisterForm 
          onSubmit={this.handleSubmit}
          step={step}
          errors={errors}
          onNextClicked={this.handleNextClicked}
          onPreviousClicked={this.handlePreviousClicked}
        />
      </Page>
    );
  }
}

function mapSateToProps(state) {
  return {
    user: state.user,
    isAuthenticated: state.user.loggedIn,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...UserActions}, dispatch)
  }
}

export default connect(mapSateToProps, mapDispatchToProps)(Register);

