import React from 'react';
import { Field } from 'redux-form';
import { renderField } from '../../RenderField';
import * as Validators from '../../Validators';


export function StepThree({errors}) {
  return (
    <>
      <h2>How can we contact you?</h2>
      <p>What is your email address?</p>
      <div className="c-form__row">
        <Field 
          name="email"
          id="email" 
          component={renderField} 
          type="text" 
          placeholder="Email"
          validate={[Validators.required, Validators.email]}
        />
        {errors.email !== undefined && <div className="c-form__error"><i>{errors.email[0]}</i></div>}
      </div>

      <p>What is your phone number?</p>
      <div className="c-form__row">
        <Field 
          name="phone"
          id="phone" 
          component={renderField} 
          type="text" 
          placeholder="Phone"
          validate={[Validators.required]}
        />
        {errors.phone !== undefined && <div className="c-form__error"><i>{errors.phone[0]}</i></div>}
      </div>
    </>
  );
}