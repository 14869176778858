import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { STORE_ACCOMMODATION_SITE, GET_ACCOMMODATION_SITES, DESTROY_ACCOMMODATION_SITE, UPDATE_ACCOMMODATION_SITE, SET_SELECTED_ACCOMMODATION_SITE } from '../actionTypes';

export function storeAccommodationSite(obj) {
  return function(dispatch) {
    return api
      .storeAccommodationSite(obj)
      .then(
        response => dispatch(storeAccommodationSiteSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function storeAccommodationSiteSuccess(response) {
  return {
    type: STORE_ACCOMMODATION_SITE,
    payload: response.data || {}
  }
}

export function getAccommodationSites() {
  return function(dispatch) {
    return api
      .getAccommodationSites()
      .then(
        response => dispatch(getAccommodationSitesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getAccommodationSitesSuccess(response) {
  return {
    type: GET_ACCOMMODATION_SITES,
    payload: response.data || {}
  }
}

export function destroyAccommodationSite(AccommodationSiteId) {
  return function(dispatch) {
    return api
      .destroyAccommodationSite(AccommodationSiteId)
      .then(
        response => dispatch(destroyAccommodationSiteSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function destroyAccommodationSiteSuccess(response) {
  return {
    type: DESTROY_ACCOMMODATION_SITE,
    payload: response.data || {}
  }
}

export function updateAccommodationSite(obj) {
  return function(dispatch) {
    return api
      .updateAccommodationSite(obj)
      .then(
        response => dispatch(updateAccommodationSiteSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateAccommodationSiteSuccess(response) {
  return {
    type: UPDATE_ACCOMMODATION_SITE,
    payload: response.data || {}
  }
}

export function setSelectedAccommodationSite(houseId) {
  return {
    type: SET_SELECTED_ACCOMMODATION_SITE,
    payload: houseId
  }
}