import React, { Component } from 'react';
import { Page, Tabbar, Tab, ToolbarButton, Toolbar, Icon, Dialog } from 'react-onsenui';
import tabs from './tabs';
import Navigator from '../Navigator';


class TabBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInstallMessage: false,
      active: 0,
      tabStacks: [
        { size: 0 },
        { size: 0 },
        { size: 0 },
        { size: 0 },
        { size: 0 },
      ],
    };
  }

  componentDidMount() {
    // Detects if device is on iOS 
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      console.log('show install message');
      this.setState({ showInstallMessage: true });
    }
  }

  renderToolbar = () => {
    const { onSidebarAction } = this.props;
    const { active, tabStacks } = this.state;
    const titles = ['Home', 'Appointments', 'House Listings', 'Service Directory', 'Covid'];

    return tabStacks[active].size <= 1 && (
      <Toolbar position="top" >
        <div className="left">
          <ToolbarButton onClick={onSidebarAction}>
            <Icon
              size={{default: 32, material: 32}}
              icon={{default: 'ion-ios-menu', material: 'md-menu'}}
            />
          </ToolbarButton>
        </div>
        <div className="center">
          {titles[active]}
        </div>
      </Toolbar>
    );
  }

  handleStackChange = (stackSize) => {
    const { active, tabStacks } = this.state;

    let newTabStacks = tabStacks;
    newTabStacks[active].size = stackSize;

    this.setState({ tabStacks: newTabStacks });

    console.log(this.state.tabStacks, stackSize);
  }

  renderTabs = (activeIndex, tabbar) => {
    return tabs.map((tab, i) => ({
      content: 
        <Navigator 
          tab={tab}
          key={`content${i}`}
          onStackChange={this.handleStackChange}
          swipeable={true}
        />,
      tab: 
        <Tab 
          icon={tab.icon} 
          key={`tab${i}`}
        />
      })
    );
  }

  render() {
    const { showInstallMessage } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Dialog isOpen={showInstallMessage}>
          <div className="c-add-to-home">
            <Icon
              className="c-add-to-home__close"
              size={{default: 26, material: 26}}
              icon={{default: 'ion-ios-close', material: 'md-close'}}
              onClick={() => this.setState({showInstallMessage: false})}
            />

            {/* <img src={logo192} className="c-add-to-home__logo" alt="App logo" /> */}

            <p>Add this app to your home screen for easy access and a better experience.</p>

            <p>
              Tap
              <Icon
                className="c-add-to-home__icon"
                size={{default: 18, material: 18}}
                icon={{default: 'ion-ios-exit', material: 'md-exit'}}
                rotate={270}
              />
              then 'Add to Home Screen'
            </p>
          </div>
        </Dialog>

        <Tabbar
          position="bottom"
          index={0}
          renderTabs={this.renderTabs}
          onPreChange={() => this.setState({showToolbar: true})}
          onPostChange={e => this.setState({active: e.activeIndex})}
        />
      </Page>
    );
  }
}

export default TabBar