import React, { useState } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'reactstrap';
import { Card } from 'react-onsenui';
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from './steps';
import { connect } from 'react-redux';
import { Button } from 'react-onsenui';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SubmitButton from '../utilities/SubmitButton';
import Loading from '../utilities/Loading';
import logo from '../../images/logo.png';


function RegisterForm(props) {
  const { 
    handleSubmit, 
    submitting,  
    fNameVal,
    lNameVal,
    ageVal,
    genderVal,
    ethnicityVal,
    emailVal,
    phoneVal,
    accomodationVal,
    accomodationSiteVal,
    addressVal,
    pwdVal,
    confPwdVal,
    step,
    onNextClicked,
    onPreviousClicked
  } = props;

  const steps = [
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive
  ];

  const nextVisible = step => {
    switch (step) {
      case 0:
        return fNameVal && lNameVal;
      case 1:
        return ageVal && genderVal && ethnicityVal;
      case 2:
        return emailVal && phoneVal;
      case 3:
        return ((accomodationVal && accomodationVal != 7) && (addressVal || accomodationVal == 5)) || (accomodationVal == 7 && accomodationSiteVal);
      case 4: 
        return (pwdVal && confPwdVal) && (pwdVal === confPwdVal)
      default:
        return fNameVal && lNameVal;
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="c-form c-form--h100">
      <TransitionGroup className="c-form__transition">      
        {steps.map((Component, i) => (
          step === i && (
            <CSSTransition
              key={i}
              timeout={600}
              classNames="step"
            >
              <Card className="c-form__container">
                <div className="c-login__logo">
                  <img className="img-fluid u-mb-1" src={logo}/>
                </div>

                <Component {...props}/> 

                <div className="c-form__button-group">
                  {submitting 
                    ? <Loading/> 
                    : (
                      <>
                        {nextVisible(step) && (
                          step === 4 
                            ? <SubmitButton>Submit</SubmitButton>
                            : (
                              <Button 
                                className="button--large--cta" 
                                onClick={onNextClicked}
                              >
                                Next
                              </Button>
                            )
                        )}

                        {step > 0 && (
                          <Button 
                            className="button--large--cta button--large--cta--secondary u-mt-1" 
                            onClick={onPreviousClicked}
                          >
                            Previous
                          </Button>
                        )}
                      </>
                    )}
                </div>
              </Card>
            </CSSTransition>
          )
        ))}
      </TransitionGroup>
    </Form>
  )
}

RegisterForm = reduxForm({
  form: 'RegisterForm'
})(RegisterForm)

const selector = formValueSelector('RegisterForm');

function mapSateToProps(state) {
  return {
    fNameVal: selector(state, 'first_name'),
    lNameVal: selector(state, 'last_name'),
    ageVal: selector(state, 'age'),
    genderVal: selector(state, 'gender'),
    ethnicityVal: selector(state, 'ethnicity'),
    emailVal: selector(state, 'email'),
    phoneVal: selector(state, 'phone'),
    accomodationVal: selector(state, 'accommodation_type_id'),
    accomodationSiteVal: selector(state, 'accommodation_site_id'),
    addressVal: selector(state, 'address'),
    pwdVal: selector(state, 'password'),
    confPwdVal: selector(state, 'confirm_password'),
    accommodationTypes: state.accommodationTypes,
    accommodationSites: state.accommodationSites.items,
  }
}

export default connect(mapSateToProps)(RegisterForm)