import React from 'react';
import { Page, Card, List, ListItem, Button, Icon } from 'react-onsenui';
import RenderToolbar from '../utilities/RenderToolbar';
import { connect } from 'react-redux';
import ServiceTypeForm from './ServiceTypeForm';
import { bindActionCreators } from 'redux';
import * as ServiceTypeActions from '../../actions/serviceType';
import cogoToast from 'cogo-toast';


function ServiceTypes({ serviceTypes, navigator, actions }) {

  const handleAddServiceTypeClicked = () => {
    navigator.pushPage({ 
      Component: ServiceTypeForm, 
      props: {
        formAction: actions.storeServiceType
      }
    });
  }

  const handleServiceTypeClicked = serviceType => {
    navigator.pushPage({ 
      Component: ServiceTypeForm,
      props: { 
        initialValues: serviceType,
        formAction: actions.updateServiceType
      }
    });
  }

  const handleDeleteServiceClicked = id => {
    const response = window.confirm('Are you sure you want to delete this service?');
    if (response) {
      actions.destroyServiceType(id).then(response => {
        console.log(response);

        cogoToast.success('Service type deleted');
      });
    }
  }

  const renderRow = (serviceType, i) => (
    <ListItem key={i}> 
      <div className="u-flex-row-between">
        <span className="u-w-50">{serviceType.name}</span>

        <div>
          <Button className="u-mr-1" onClick={() => handleServiceTypeClicked(serviceType)}>
            <Icon icon="edit"/>
          </Button>

          <Button onClick={() => handleDeleteServiceClicked(serviceType.id)}>
            <Icon icon="trash"/>
          </Button>
        </div>
      </div>
    </ListItem>
  );

  const renderToolbar = () => <RenderToolbar title="Service Types" defaultBackButton={true}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Button 
        onClick={handleAddServiceTypeClicked}
        className="button--large--cta u-mb-1"
      >
        Add Service Type
      </Button>

      <Card>
        <h3>Service Types</h3>
        
        <List
          dataSource={serviceTypes}
          renderRow={renderRow}
        />
      </Card>
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    serviceTypes: state.serviceTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ServiceTypeActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypes);
