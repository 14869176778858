import { 
  GET_USERS, 
  SET_USERS_CURRENT_PAGE, 
  SET_USERS_SEARCH_TERM,
  UPDATE_USER,
} from '../actionTypes';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const initialState = {
  allIds: [],
  byId: {},
  currentPage: 1,
  itemsPerPage: 50,
  totalItems: 0,
  searchTerm: '',
  rolesFilter: 0
};

const byId = (state = initialState.byId, { type, payload = null}) => {
  switch(type) {
    case GET_USERS:
      return getUsersById(state, payload);
    case UPDATE_USER: 
      return updateUsersById(state, payload);
    default:
      return state;
  }
}

function getUsersById(state, payload) {
  return {
    ...state,
    ...payload.data.reduce((obj, user) => { 
      obj[user.id] = user;
      return obj;
    }, {})
  }
}

function updateUsersById(state, payload) {
  return {
    ...state,
    [payload.data.id]: payload.data 
  }
}

const allIds = (state = initialState.allIds, { type, payload = null}) => {
  switch(type) {
    case GET_USERS:
      return getAllIds(state, payload);
    default:
      return state;
  }
}

function getAllIds(state, payload) {
  return payload.data.map(user => user.id);
}


const currentPage = (state = initialState.currentPage, { type, payload = null}) => {
  switch(type) {
    case SET_USERS_CURRENT_PAGE:
      return payload;
    default:
      return state;
  }
}

const totalItems = (state = initialState.totalItems, { type, payload = null}) => {
  switch(type) {
    case GET_USERS:
      return payload.data.length;
    default:
      return state;
  }
}

const searchTerm = (state = initialState.searchTerm, { type, payload = null}) => {
  switch(type) {
    case SET_USERS_SEARCH_TERM:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  allIds,
  currentPage,
  totalItems,
  searchTerm,
});

const selectUsersById = state => state.users.byId;
const selectUsersAllIds = state => state.users.allIds;
const selectUserId = (state, id) => id;

const selectUsersCurrentPage = state => state.users.currentPage;
const selectUsersItemsPerPage = state => state.users.itemsPerPage;
const selectUsersTotalItems = state => state.users.totalItems;
const selectUsersSearchTerm = state => state.users.searchTerm;
const selectUserRolesFilter = state => state.users.rolesFilter;

export const selectUser = createSelector(
  [selectUsersById, selectUserId],
  (usersById, id) => {
    let user = usersById[id] ? {...usersById[id]} : undefined;

    // if(user) {
    //   user.rolesList = user.roles.map(role => role.name);
    //   user.roles = user.roles.map(role => role.id);
    // }

    return user;
  }
)

export const selectUsers = createSelector(
  [selectUsersAllIds, selectUsersById],
  (usersAllIds, usersById) => usersAllIds.map(id => usersById[id])
)

// TODO: Less jankey
export const selectBookableUsers = createSelector(
  [selectUsers],
  (users) => {
    return users.filter(user => user.roles.filter(r => r.name === 'Bookable Staff').length > 0 && user.appointment_availabilities && user.appointment_availabilities.length > 0)
  }
)

export const selectUsersBySearchTerm = createSelector(
  [selectUsersAllIds, selectUsersById, selectUsersSearchTerm],
  (usersAllIds, usersById, searchTerm) => {
    let users = [...usersAllIds];

    if(searchTerm !== '') {
      users = users.filter(id => {
        if(searchTerm.includes(' ')) {
          const aSeachTerm = searchTerm.split(' ');
          
          return (
            usersById[id].first_name.toLowerCase().includes(aSeachTerm[0].toLowerCase()) &&
            usersById[id].last_name.toLowerCase().includes(aSeachTerm[1].toLowerCase())
          );

        } else {
          return (
            usersById[id].first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            usersById[id].last_name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
      });
    }

    return users.map(id => usersById[id]);
  }
)