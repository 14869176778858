import React from 'react';
import { Card, Page, Button } from 'react-onsenui';
import RenderToolbar from '../utilities/RenderToolbar';
import UserForm from './UserForm'
import { connect } from 'react-redux';
import { selectUser } from '../../reducers/users';

function User(props) {
  const { user, popPage, pushPage } = props;

  const renderToolbar = () => (
    <RenderToolbar 
      title={`${user.first_name} ${user.last_name}'s Details`} 
      backAction={popPage}
    />
  )

  const handleEditClicked = () => {
    pushPage(UserForm, 'user-form', { 
      initialValues: { 
        ...user, 
        admin: user.roles.filter(r => r.pivot.role_id === 3).length > 0, 
        bookable: user.roles.filter(r => r.pivot.role_id === 2).length > 0
      }
    });
  }

  return (
    <Page renderToolbar={renderToolbar}>
      <Card>
        <div className="c-profile">
          {user.image && <img className="img-fluid" src={user.image}/>}
          <h3>User Details</h3>
          <p className="c-profile__subheading">Full Name</p>
          <p className="c-profile__item">{user.first_name + ' ' + user.last_name}</p>
          <p className="c-profile__subheading">Email</p>
          <p className="c-profile__item">{user.email}</p>
          
          {!user.admin && (
            <>
              <p className="c-profile__subheading">Age</p>
              <p className="c-profile__item">{user.age}</p>
              <p className="c-profile__subheading">Gender</p>
              <p className="c-profile__item">{user.gender}</p>
              <p className="c-profile__subheading">Ethnicity</p>
              <p className="c-profile__item">{user.ethnicity}</p>
              <p className="c-profile__subheading">Phone</p>
              <p className="c-profile__item">{user.phone}</p>
              {user.accomodation_type && (
                <>
                  <p className="c-profile__subheading">Accomodation Type</p>
                  <p className="c-profile__item">{user.accomodation_type.name}</p>
                </>
              )}
              
              {user.accomodation_type && user.accomodation_type.name !== 'Un-housed' && (
                <>
                  <p className="c-profile__subheading">Address</p>
                  <p className="c-profile__item">{user.address}</p>
                </>
              )}
            </>
          )}

        </div>
      </Card>

      <Button 
        className="button--large"
        onClick={handleEditClicked}
      >
        Edit User
      </Button>
    </Page>
  );
}

function mapStateToProps(state, initProps) {
  return {
    user: selectUser(state, initProps.userId)
  }
}

export default connect(mapStateToProps)(User)