import React from 'react';
import { Page, Card, Button, Col, Row } from 'react-onsenui';
import { Slide } from 'react-slideshow-image';
import ApplicationForm from '../ApplicationForm';
import RenderToolbar from '../utilities/RenderToolbar';
import BathtubIcon from '@material-ui/icons/Bathtub';
import KingBedIcon from '@material-ui/icons/KingBed';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import HouseForm from './HouseForm';
import { bindActionCreators } from 'redux';
import * as HouseActions from "../../actions/house";
import { connect } from 'react-redux';
import { selectHouse, selectHousePhotos } from '../../reducers/houses';

function HouseListing(props) {
  const { pushPage, popPage, actions, isAdmin, house, photos } = props;
  
  const handleSubmitClicked = () => pushPage(ApplicationForm, 'application', props)

  const handleEditHouseClicked = () => {
    const props = { 
      initialValues: { ...house },
      formSubmitAction: actions.updateHouse,
      title: 'Edit House',
      buttonText: 'Save Changes'
    }

    pushPage(HouseForm, 'HouseForm', props);
  }

  const handleDeleteHouseClicked = () => {
    actions.destroyHouseById(house.id).then(response => {
      console.log(response);
    
      popPage();
    });
  }

  return (
    <Page renderToolbar={() => <RenderToolbar backAction={popPage} title={house && house.street_address}/>}>
      {house && (
        <Card>
          <div className="c-listing">
            {photos && (
              <div className="slide-container">
                <Slide arrows={false} autoplay={photos.length > 1} canSwipe={photos.length > 1}>
                  {photos.map((item, i) => (
                    <div key={i} className="each-slide">
                      <img src={`https://st-wact.s3.amazonaws.com/${item.photo}`}/>
                    </div>
                  ))}
                </Slide>
              </div>
            )}
          
            <Row>
              <h3>{house.street_address}</h3>
              {house.room_number && (
                <h3 className="c-listing__item u-ml-025">, Room No: {house.room_number}</h3>
              )}
            </Row>

            <Row className="u-mb-1">
              <p className="c-listing__item u-mr-025">{house.suburb + ', '}</p>
              <p className="c-listing__item">{house.city}</p>
            </Row>
            
            <p>{house.description}</p>
            
            {house.garaging ? 
              <>
                <p className="c-listing__subheading">Garaging:</p>
                <p className="c-listing__item">{house.garaging}</p><br />
              </>
            : null}
            
            {house.driveway ? 
              <>
                <p className="c-listing__subheading">Driveway:</p>
                <p className="c-listing__item">{house.driveway}</p><br />
              </>
            : null}

            {house.fencing ? 
              <>
                <p className="c-listing__subheading">Fencing:</p>
                <p className="c-listing__item">{house.fencing}</p><br />
              </>
            : null}
            
            <Row>
              <Col>
                <div className="c-listing__icon-group">
                  <KingBedIcon/>
                  <div className="c-listing__icon-group__text">
                    <p className="c-listing__subheading">Bedrooms</p>
                    <p className="c-listing__item">{house.bedrooms}</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="c-listing__icon-group">
                  <BathtubIcon/>
                  <div className="c-listing__icon-group__text">
                    <p className="c-listing__subheading">Bathrooms</p>
                    <p className="c-listing__item">{house.bathrooms}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="c-listing__icon-group">
                  <DriveEtaIcon/>
                  <div className="c-listing__icon-group__text">
                    <p className="c-listing__subheading">Car Parks</p>
                    <p className="c-listing__item">{house.carparks}</p>
                  </div>
                </div>
              </Col>
              {/* <Col>
                <div className="c-listing__icon-group">
                  <SquareFootIcon/>
                  <div className="c-listing__icon-group__text">
                    <p className="c-listing__subheading">Square Feet</p>
                    <p className="c-listing__item">{house.square_feet}</p>
                  </div>
                </div>
              </Col> */}
            </Row>
            <div className="c-listing__icon-group">
              <FireplaceIcon/>
              <div className="c-listing__icon-group__text">
                <p className="c-listing__subheading">Heating</p>
                <p className="c-listing__item">{house.heating_type.name}</p>
              </div>
            </div>
          </div>
        </Card>
      )}

      {isAdmin ? (
        <Row className="u-mt-1">
          <Col className="u-mr-05">
            <Button className="button--large--cta" onClick={handleEditHouseClicked}>Edit</Button>
          </Col>
          <Col className="u-ml-05">
            <Button className="button--large--cta u-bg--red" onClick={handleDeleteHouseClicked}>Delete</Button>
          </Col>
        </Row>
      ) : (
        <Button className="button--large--cta" onClick={handleSubmitClicked}>Submit an application</Button>
      )}
    </Page>
  )
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.admin,
    house: selectHouse(state),
    photos: selectHousePhotos(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...HouseActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HouseListing)