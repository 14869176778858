import React from 'react';
import { Page, Card } from 'react-onsenui';
import { reduxForm, change, formValueSelector, submit } from 'redux-form';
import DropzoneField from '../DropzoneField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'reactstrap';
import { uploadFile } from '../../utils/s3';
import RenderToolbar from '../utilities/RenderToolbar';
import { uuid } from 'uuidv4';
import FormPhoto from '../FormPhoto';

function ReferencesForm(props) {
  const { rentalRefOneDocs, rentalRefTwoDocs, characterRefDocs, actions, handleSubmit, submitting, popPage } = props;

  const handleDrop = (file, field, documents) => {

    if (file.length) {
      uploadFile(file[0])
        .then(data => {
          // Initialise new documents with stored photo
          let newDocuments = [{id: uuid(), document: data.Location, selected: false}];
  
          // If we have exisitng documents merge the two arrays
          if (documents !== undefined && documents.length > 0) {
            newDocuments = [...documents, ...newDocuments];
          }
  
          actions.change('ReferencesForm', field, newDocuments);

        })
        .catch(err => console.log(err));
    }
  }

  const handleDocumentClicked = (id, field, documents) => {
    let newDocuments = documents;

    newDocuments = newDocuments.map(item => {
      let newItem = {...item};
      if (newItem.id === id) {
        newItem.selected = !newItem.selected;
      }
      return newItem;
    });

    actions.change('ReferencesForm', field, newDocuments);
  }

  const handleRemoveDocumentClicked = (e, id, field, documents) => {
    e.stopPropagation();

    let newDocuments = documents;

    newDocuments = newDocuments.filter(item => {
      let newItem = {...item};
      if (newItem.id !== id) {
        return newItem;
      }
    });

    actions.change('ReferencesForm', field, newDocuments);
  }

  return (
    <Page renderToolbar={() => <RenderToolbar title="References" defaultBackButton={true} backAction={popPage} />}>
      <Card>
        <h5>You can provide copies of your reference documents here.</h5>
        <p>You can upload the file from your phone or take a picture.</p>
      </Card>
      <Form onSubmit={handleSubmit} className="c-form">
        <Card>
          <p className="u-mt-0">Rental Reference One <span className="c-form__text--required"> - Required</span></p>
          <DropzoneField 
            icon="ion-ios-camera"
            onDrop={(acceptedFiles) => handleDrop(acceptedFiles, 'rental_reference_one_documents', rentalRefOneDocs)}
          />
          {rentalRefOneDocs && rentalRefOneDocs.length > 0 && rentalRefOneDocs.map((d, i)=> (
            <FormPhoto 
              key={i}
              photo={d.document}
              selected={d.selected}
              onClick={() => handleDocumentClicked(d.id, 'rental_reference_one_documents', rentalRefOneDocs)}
              onRemovePhotoClicked={e => handleRemoveDocumentClicked(e, d.id, 'rental_reference_one_documents', rentalRefOneDocs)}
            />
          ))}
        </Card>

        <Card>
          <p className="u-mt-0">Rental Reference Two <span className="c-form__text--required"> - Required</span></p>
          <DropzoneField 
            icon="ion-ios-camera"
            onDrop={(acceptedFiles) => handleDrop(acceptedFiles, 'rental_reference_two_documents', rentalRefTwoDocs)}
          />
          {rentalRefTwoDocs && rentalRefTwoDocs.length > 0 && rentalRefTwoDocs.map((d, i) => (
            <FormPhoto 
              key={i}
              photo={d.document}
              selected={d.selected}
              onClick={() => handleDocumentClicked(d.id, 'rental_reference_two_documents', rentalRefTwoDocs)}
              onRemovePhotoClicked={e => handleRemoveDocumentClicked(e, d.id, 'rental_reference_two_documents', rentalRefTwoDocs)}
            />
          ))}
        </Card>

        <Card>
          <p className="u-mt-0">Character Reference <span className="c-form__text--required"> - Required</span></p>
          <DropzoneField 
            icon="ion-ios-camera"
            onDrop={(acceptedFiles) => handleDrop(acceptedFiles, 'character_reference_documents', characterRefDocs)}
          />
          {characterRefDocs && characterRefDocs.length > 0 && characterRefDocs.map((d, i) => (
            <FormPhoto 
              key={i}
              photo={d.document}
              selected={d.selected}
              onClick={() => handleDocumentClicked(d.id, 'character_reference_documents', characterRefDocs)}
              onRemovePhotoClicked={e => handleRemoveDocumentClicked(e, d.id, 'character_reference_documents', characterRefDocs)}
            />
          ))}
        </Card>
      </Form>
    </Page>
  );
}

function onChange(values, dispatch, props, previousValues) {
  if (values.rental_reference_one_documents && previousValues.rental_reference_one_documents) {
    if (values.rental_reference_one_documents.length != previousValues.rental_reference_one_documents.length) {
      dispatch(submit('ReferencesForm'))
    }
  }

  if (values.rental_refernce_two_documents && previousValues.rental_refernce_two_documents) {
    if (values.rental_refernce_two_documents.length != previousValues.rental_refernce_two_documents.length) {
      dispatch(submit('ReferencesForm'))
    }
  }

  if (values.character_reference_documents && previousValues.character_reference_documents) {
    if (values.character_reference_documents.length != previousValues.character_reference_documents.length) {
      dispatch(submit('ReferencesForm'))
    }
  }
}

ReferencesForm = reduxForm({
  form: 'ReferencesForm',
  destroyOnUnmount: true,
  onChange
})(ReferencesForm)

const selector = formValueSelector('ReferencesForm');

function mapStateToProps(state) {  
  return {
    rentalRefOneDocs: selector(state, 'rental_reference_one_documents'),
    rentalRefTwoDocs: selector(state, 'rental_reference_two_documents'),
    characterRefDocs: selector(state, 'character_reference_documents')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesForm)