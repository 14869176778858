import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import { ProgressCircular } from 'react-onsenui';
import ons from 'onsenui';
import SubmitButton from '../utilities/SubmitButton';

function ResetPasswordConfirmForm(props) {
  const { handleSubmit, submitting, error } = props;

  const isAndroid = ons.platform.isAndroid();

  return (
    <form className="c-form" method="POST" onSubmit={handleSubmit}>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field 
          id="email"
          name="email"
          component={renderField} 
          type="text" 
          placeholder="Your email"
          validate={[Validators.required, Validators.email]}
        />
      </div>

      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field 
          name="password"
          id="password" 
          component={renderField} 
          type="password" 
          placeholder="New password"
          validate={[Validators.required, Validators.password]}
        />
      </div>

      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field 
          name="confirm_password"
          id="confirm_password" 
          component={renderField} 
          type="password" 
          placeholder="Confirm new password"
          validate={[Validators.required, Validators.confirmPassword]}
        />
      </div>

      {error && (
        <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
          <p className="c-form__error u-mt-0">
            {error}
          </p>
        </div>
      )}
      
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        {submitting 
            ? <ProgressCircular indeterminate />
            : <SubmitButton>Reset</SubmitButton>
          }
      </div>
    </form>
  ); 
}

export default reduxForm({
  form: 'ResetPasswordConfirmForm'
})(ResetPasswordConfirmForm)