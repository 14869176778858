import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { 
  GET_SERVICE_TYPES, 
  STORE_SERVICE_TYPE, 
  UPDATE_SERVICE_TYPE, 
  DESTROY_SERVICE_TYPE 
} from '../actionTypes';

export function getServiceTypes() {
  return function(dispatch) {
    return api
      .getServiceTypes()
      .then(
        response => dispatch(getServiceTypesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function getServiceTypesSuccess(response) {
  return {
    type: GET_SERVICE_TYPES,
    payload: response.data || {}
  }
}

export function storeServiceType(obj) {
  return function(dispatch) {
    return api
      .storeServiceType(obj)
      .then(
        response => dispatch(storeServiceTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function storeServiceTypeSuccess(response) {
  return {
    type: STORE_SERVICE_TYPE,
    payload: response.data || {}
  }
}

export function updateServiceType(obj) {
  return function(dispatch) {
    return api
      .updateServiceType(obj)
      .then(
        response => dispatch(updateServiceTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function updateServiceTypeSuccess(response) {
  return {
    type: UPDATE_SERVICE_TYPE,
    payload: response.data || {}
  }
}

export function destroyServiceType(id) {
  return function(dispatch) {
    return api
      .destroyServiceType(id)
      .then(
        response => dispatch(destroyServiceTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function destroyServiceTypeSuccess(response) {
  return {
    type: DESTROY_SERVICE_TYPE,
    payload: response.data || {}
  }
}