import { DESTROY_SERVICE, GET_SERVICES, SET_SELECTED_SERVICE, STORE_SERVICE, UPDATE_SERVICE } from '../actionTypes';
import { createSelector } from 'reselect';

const initialState = {
  items: [],
  selectedServiceId: 0,
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_SERVICES:
      return getServices(state, payload);
    case STORE_SERVICE:
      return storeService(state, payload);
    case DESTROY_SERVICE:
      return destroyService(state, payload);
    case UPDATE_SERVICE: 
      return updateService(state, payload);
    case SET_SELECTED_SERVICE:
      return setSelectedService(state, payload)
    default:
      return state;
  }
}

function getServices(state, payload) {
  return {
    ...state,
    items: payload.services,
  };
}

function storeService(state, payload) {
  return {
    ...state,
    items: [...state.items, payload.service],
  };
}

function destroyService(state, payload) {
  return {
    ...state,
    items: state.items.filter(item => item.id != payload.serviceId)
  }
}

function updateService(state, payload) {
  return { 
    ...state,
    items: state.items.map(item => item.id == payload.service.id ? payload.service : item)
  }
}

function setSelectedService(state, payload) {
  return {
    ...state,
    selectedServiceId: payload
  }
}

export const selectServices = state => state.services.items;
export const selectServiceId = state => state.services.selectedServiceId;

export const selectService = createSelector([selectServices, selectServiceId], (services, serviceId) => {
  return services.filter(h => h.id === serviceId)[0];
});

export default reducer;