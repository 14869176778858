import { AUTH_CHECK, GET_AUTH_USER, GET_USER, LOGIN, LOGOUT, UPDATE_USER_ACCOMODATION_DETAILS, STORE_APPOINTMENT, UPDATE_USER_APPOINTMENT_AVAILABILITY, UPDATE_USER_MOVING_CHECKLIST, UPDATE_AUTH_USER } from '../actionTypes';
import { createSelector } from 'reselect';

const initialState = {
  id: null,
  loggedIn: false,
  confirmed: false,
  name: '',
  email: '',
  admin: false,
  bookable: false,
  token: '',
  moving_checklist: {}
}

const reducer = (state = initialState, { type, payload = null}) => {
  switch(type) {
    case AUTH_CHECK:
      return checkAuth(state, payload);
    case GET_USER: 
      return getUser(state, payload);
    case GET_AUTH_USER:
      return getAuthUser(state, payload);
    case UPDATE_AUTH_USER:
      return updateAuthUser(state, payload);
    case LOGIN:
      return login(state, payload);
    case LOGOUT:
      return logout(state);
    case STORE_APPOINTMENT:
      return storeAppointment(state, payload)
    case UPDATE_USER_MOVING_CHECKLIST:
      return updateUserMovingChecklist(state, payload);
    case UPDATE_USER_APPOINTMENT_AVAILABILITY:
      return updateUserAppointmentAvailability(state, payload);
    case UPDATE_USER_ACCOMODATION_DETAILS:
      return updateUserAccomodationDetails(state, payload);
    default:
      return state;
  }
}

function checkAuth(state, payload) {
  state = Object.assign({}, state, {
    loggedIn: payload
  });

  return state;
}

function getAuthUser(state, payload) {
  return {
    ...state, 
    ...payload.user,
    admin: payload.admin,
    bookable: payload.bookable,
    loggedIn: true,
  }
}

function getUser(state, payload) {
  return {
    ...state, 
    ...payload.user,
    admin: payload.admin,
    bookable: payload.bookable,
    loggedIn: true,
  }
}

function updateAuthUser(state, payload) {
  return {
    ...state,
    ...payload.data,
  }
}

function login(state, payload) {
  localStorage.setItem('access_token', payload.token);
  
  return {
    ...state,
    token: payload.token
  }
}

function logout(state) {
  localStorage.removeItem('access_token');

  return {
    ...state,
    loggedIn: false,
  };
}

function updateUserMovingChecklist(state, payload) {
  return {
    ...state,
    ...payload.data,
    accommodation_type: {
      ...payload.data.accomodation_type
    }
  }
}

function storeAppointment(state, payload) {
  return {
    ...state,
    appointments: [
      ...state.appointments,
      payload.data
    ]
  }
}

function updateUserAppointmentAvailability(state, payload) {
  return {
    ...state,
    appointment_availabilities: [
      ...payload.data  
    ]
  }
}

function updateUserAccomodationDetails(state, payload) {
  return {
    ...state,
    ...payload.data
  }
}

export const selectMovingChecklist = state => state.user.moving_checklist;

export const selectMovingChecklistDocuments = createSelector([selectMovingChecklist], movingChecklist => {
  if (movingChecklist) {
    return movingChecklist.documents;
  }

  return null;
});

export const selectPersonalDocuments = createSelector([selectMovingChecklistDocuments], documents => {
  if (documents) {
    return {
      photo_identification_documents: documents.filter(d => d.checklist_field.name === 'photo_identification'),
      police_vetting_documents: documents.filter(d => d.checklist_field.name === 'police_vetting'),
      credit_check_documents: documents.filter(d => d.checklist_field.name === 'credit_check'),
    }
  }

  return null;
});

export const selectReferenceDocuments = createSelector([selectMovingChecklistDocuments], documents => {
  if (documents) {
    return {
      rental_reference_one_documents: documents.filter(d => d.checklist_field.name === 'rental_reference_one'),
      rental_reference_two_documents: documents.filter(d => d.checklist_field.name === 'rental_reference_two'),
      character_refernce_documents: documents.filter(d => d.checklist_field.name === 'character_refernce'),
    }
  }

  return null;
});

export default reducer;