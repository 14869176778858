import React from 'react'
import ReactDOM from 'react-dom'
import { Provider} from 'react-redux';
import configureAppStore from '../storeConfig';
import { authCheck, getAuthUser, logout } from '../actions/user';
import { AJAX_CALL_ERROR, AUTH_CHECK } from '../actionTypes';
import Main from "../components/Main";
import ErrorBoundary from '../components/ErrorBoundary';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../sass/app.scss';
import { Navigator } from 'react-onsenui';
import { getServiceTypes } from '../actions/serviceType';
import { getHeatingTypes } from '../actions/heatingType';
import { getBuildingTypes } from '../actions/buildingType';

const store = configureAppStore();

store.dispatch(authCheck()).then(response => {
  console.log(response);
  if (response.type === AUTH_CHECK && response.payload) {

    console.log('auth check payload', response.payload);
    
    store.dispatch(getAuthUser()).then(response => {
      if (response.type === AJAX_CALL_ERROR) {
        store.dispatch(logout());
      }

      store.dispatch(getServiceTypes());
      store.dispatch(getHeatingTypes());
      store.dispatch(getBuildingTypes());
    });
    // new ServiceWorker().init();
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Navigator
        renderPage={(route, navigator) => <route.Component navigator={navigator} key={route.Component.WrappedComponent ? route.Component.WrappedComponent.name : 'key'} {...route.props} />}
        initialRoute={{
          Component: Main,
          props: { key: 'initialRoute' },
        }}
        swipeable={true}
      />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('app')
);