import { GET_HEATING_TYPES, STORE_HEATING_TYPE, UPDATE_HEATING_TYPE, DESTROY_HEATING_TYPE } from '../actionTypes';

const initialState = []

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_HEATING_TYPES:
      return getHeatingTypes(payload);
    case STORE_HEATING_TYPE:
      return storeHeatingType(state, payload);
    case UPDATE_HEATING_TYPE:
      return updateHeatingType(state, payload);
    case DESTROY_HEATING_TYPE:
      return destroyHeatingType(state, payload);
    default:
      return state;
  }
}

function getHeatingTypes(payload) {
  return [
    ...payload.data,
  ];
}

function storeHeatingType(state, payload) {
  return [
    ...state,
    payload.data
  ]
}

function updateHeatingType(state, payload) {
  return [
    ...state.filter(s => s.id !== payload.data.id),
    payload.data
  ]
}

function destroyHeatingType(state, payload) {
  return [
    ...state.filter(s => s.id !== payload.data.id)
  ]
}


export default reducer;