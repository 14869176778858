import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import user from './user';
import appointments from './appointments';
import houses from './houses';
import services from './services';
import serviceTypes from './serviceTypes';
import heatingTypes from './heatingTypes';
import buildingTypes from './buildingTypes';
import accommodationTypes from './accommodationTypes';
import users from './users';
import reports from './reports';
import accommodationSites from './accommodationSites';

export default combineReducers({
  user,
  users,
  appointments,
  houses,
  services,
  serviceTypes,
  heatingTypes,
  buildingTypes,
  accommodationTypes,
  reports,
  accommodationSites,
  form: formReducer
});