import React, { Component } from 'react';
import { Splitter, SplitterSide, SplitterContent, Page } from 'react-onsenui';
import TabBar from './tabs/TabBar';
import SideBar from './SideBar';
import Reporting from './Reporting';
import Resources from './Resources';
import ServiceTypes from './service-types/ServiceTypes';
import BuildingTypes from './building-types/BuildingTypes';
import HeatingTypes from './heating-types/HeatingTypes';
import Profile from './Profile';


export default class MasterDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  // Toggle sidebar bool
  handleSideBarAction = () => {
    const { isOpen } = this.state;
    this.setState({isOpen: !isOpen});
  }

  // Handles onsen side bar event, ensures that sidebar dosent get re-toggled
  handleSidebarClose = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.setState({isOpen: false});
    }
  }

  handleSidebarItemClicked = component => {
    const { navigator } = this.props;

    navigator.pushPage({Component: component});
    this.handleSideBarAction();
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Page>
        <Splitter>
          <SplitterSide
            side="left"
            width="75%"
            isOpen={isOpen}
            collapse="collapse"
            swipeable={true}
            animation="overlay"
            animationOptions={{duration: -0.2, timing: 'ease-in'}}
            onClose={this.handleSidebarClose}
          >
            <SideBar
              onProfileClicked={() => this.handleSidebarItemClicked(Profile)}
              onResourcesClicked={() => this.handleSidebarItemClicked(Resources)}
              onReportingClicked={() => this.handleSidebarItemClicked(Reporting)}
              onServiceTypesClicked={() => this.handleSidebarItemClicked(ServiceTypes)}
              onBuildingTypesClicked={() => this.handleSidebarItemClicked(BuildingTypes)}
              onHeatingTypesClicked={() => this.handleSidebarItemClicked(HeatingTypes)}
            />
          </SplitterSide>
          <SplitterContent>
            <TabBar onSidebarAction={this.handleSideBarAction} />
          </SplitterContent>
        </Splitter>
      </Page>
    );
  }
}