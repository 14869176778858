import React, { useState, useRef, useEffect } from "react";
import { Form, Field, reduxForm, formValueSelector } from "redux-form";
import { renderField } from "../RenderField";
import * as Validators from "../Validators";
import { connect } from "react-redux";
import { Page, Card } from 'react-onsenui';
import RenderToolbar from "../utilities/RenderToolbar";
import { bindActionCreators } from "redux";
import * as UserActions from "../../actions/user";
import SubmitButton from '../utilities/SubmitButton';
import Loading from '../utilities/Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { UPDATE_USER_APPOINTMENT_AVAILABILITY } from "../../actionTypes";
import cogoToast from 'cogo-toast';


function AppointmentAvailabilityForm(props) {
  const { availableTimes, popPage, submitting, handleSubmit, actions, user, day } = props;

  const [times, setTimes] = useState(availableTimes.map(d => new Date(d)));
  const [showTimeError, setShowTimeError] = useState(false);

  const datePickerRef = useRef();

  const highlightSelectedTimes = () => {
    if (datePickerRef.current) {
      const listItemEls = datePickerRef.current.querySelectorAll('.react-datepicker__time-list-item');
      const timeslots = times.map(t => moment(t).format('LT'));

      listItemEls.forEach(listItemEl => {
        if (timeslots.includes(listItemEl.textContent)) {
          listItemEl.classList.add('timeslot-selected');
        } else {
          listItemEl.classList.remove('timeslot-selected');
        }
      });
    }
  }

  useEffect(() => setTimes(availableTimes.map(d => new Date(d))), [availableTimes]);
  useEffect(highlightSelectedTimes);
  useEffect(highlightSelectedTimes, [times]);

  let nonBookableTimeslots = [];
  for (let i = 0; i < 8; i++) {
    nonBookableTimeslots.push(moment(new Date(0,0,0,i)).format('HH:mm:ss'));
    nonBookableTimeslots.push(moment(new Date(0,0,0,i,30)).format('HH:mm:ss'));
  }
  for (let i = 17; i < 24; i++) {
    nonBookableTimeslots.push(moment(new Date(0,0,0,i)).format('HH:mm:ss'));
    nonBookableTimeslots.push(moment(new Date(0,0,0,i,30)).format('HH:mm:ss'));
  }

  const handleFormSubmit = values => {
    if (times.length <= 0) {
      setShowTimeError(true);
      return;
    }

    const timeStrings = times.map(time => moment(time).format('HH:mm:ss'));

    return actions.updateUserAppointmentAvailability({
      ...values,
      times: timeStrings,
      user_id: user.id
    }).then(response => {
      console.log(response);

      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      
      if (response.type === UPDATE_USER_APPOINTMENT_AVAILABILITY) {
        cogoToast.success(`Your available times for ${days[parseInt(day)+1]} have been updated`);

        popPage();
      }
    });
  }

  const hadleTimesChanged = time => {
    const dateTime = new Date(time);

    const timeStrings = times.map(time => moment(time).format('LT'));
    const dateTimeString = moment(dateTime).format('LT');

    const newTimes = timeStrings.includes(dateTimeString)
      ? times.filter(t => moment(t).format('LT') !== dateTimeString)
      : [...times, dateTime];

    setTimes(newTimes);
  }

  const filterTimes = dateTime => {
    const time = moment(new Date(dateTime)).format('HH:mm:ss');
    return !nonBookableTimeslots.includes(time);
  }

  const renderToolbar = () => <RenderToolbar title="Set my availability" backAction={popPage}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <p>Select a day of the week</p>
          <div className="c-form__row">
            <Field
              id="day"
              name="day"
              type="select"
              placeholder="Select day"
              component={renderField}
              options={[
                {name: 'Monday',    id: 1},
                {name: 'Tuesday',   id: 2},
                {name: 'Wednesday', id: 3},
                {name: 'Thursday',  id: 4},
                {name: 'Friday',    id: 5},
              ]}
              validators={Validators.required}
            />
          </div>
        
          {day && (
            <div className="c-form__timeslot" ref={datePickerRef}>
              <p>When are you avaiable for an appointment</p>
              <DatePicker
                selected={times[times.length-1]}
                dateFormat="h:mm aa"
                placeholderText="Select a timeslot"
                onChange={hadleTimesChanged}
                filterTime={filterTimes}
                showTimeSelect
                showTimeSelectOnly
                inline
              />
              {showTimeError && <div className="c-form__error"><i>Please select a timeslot</i></div>}
            </div>
          )}

        </Card>
        {submitting 
          ? <Loading/>
          : <SubmitButton>Save Availability</SubmitButton>
        }
      </Form>
    </Page>
  );
}

AppointmentAvailabilityForm = reduxForm({
  form: 'AppointmentAvailabilityForm',
})(AppointmentAvailabilityForm)

const selector = formValueSelector('AppointmentAvailabilityForm');

function mapStateToProps(state) {
  let user = state.user;
  let day = selector(state, 'day');
  
  const availabilities = user.appointment_availabilities.filter(a => parseInt(a.day) === parseInt(day));
  let availableTimes = availabilities.length ? availabilities.map(a => a.time) : [];

  console.log('available times', availableTimes)

  return {
    user,
    availableTimes,
    day,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentAvailabilityForm)