import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouterNavigator, RouterUtil } from 'react-onsenui';

class Navigator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevNode: '',
      sidenavOpen: false,
      location: window.location.pathname,
      routeConfig: RouterUtil.init([{
        component: props.tab.component,
        props: {
          key: 'home',
          pushPage: (...args) => this.pushPage(...args),
        }
      }]),
    }
  }

  pushPage(page, key, props) {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.push({
      routeConfig,
      route: {
        component: page,
        props: {
          key: key,
          popPage: () => this.popPage(),
          pushPage: (...args) => this.pushPage(...args),
          ...props,
        }
      }
    });

    this.props.onStackChange(routeConfig.routeStack.length + routeConfig.processStack.length);

    this.setState({routeConfig});
  }

  popPage(options = {}) {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.pop({
      routeConfig,
      options: {
        ...options
      }
    });

    this.props.onStackChange(routeConfig.routeStack.length - routeConfig.processStack.length);
    
    this.setState({routeConfig});
  }

  onPostPush() {
    const routeConfig = RouterUtil.postPush(this.state.routeConfig);

    this.setState({routeConfig});
  }

  onPostPop() {
    const routeConfig = RouterUtil.postPop(this.state.routeConfig);
    this.setState({routeConfig});
  }

  renderPage(route) {
    const props = route.props || {};
    
    return <route.component {...props} />
  }

  render() {
    const { routeConfig } = this.state;

    console.log('Route Config', routeConfig);
    return(
      <RouterNavigator
        swipeable={true}
        swipePop={options => this.popPage(options)}
        routeConfig={routeConfig}
        renderPage={this.renderPage}
        onPostPush={() => this.onPostPush()}
        onPostPop={() => this.onPostPop()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
    isAuthenticated: state.user.loggedIn
  }
}

export default connect(mapStateToProps)(Navigator)