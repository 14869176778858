import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Form, Field, formValueSelector } from 'redux-form';
import * as UserActions from '../../actions/user';
import RenderToolbar from '../utilities/RenderToolbar';
import { Page, Card } from 'react-onsenui';
import { renderField } from '../RenderField';
import SubmitButton from '../utilities/SubmitButton';
import * as Validators from '../Validators';
import Loading from '../utilities/Loading';


function AccomodationDetailsForm(props) {
  const { popPage, actions, submitting, handleSubmit, accommodationTypes, selectedType } = props;

  const handleUserSubmit = values => {
    return actions.updateUserAccomodationDetails(values).then(response => {
      console.log(response);

      popPage();
    });
  }

  const renderToolbar = () => (
    <RenderToolbar 
      title="Update Housing Status"
      backAction={popPage}
    />
  )

  return (
    <Page renderToolbar={renderToolbar}>
      <Form onSubmit={handleSubmit(handleUserSubmit)} className="c-form">
        <Card>
          <p className="c-form__label">Accomodation</p>
          <div className="c-form__row">
            <Field 
              id="accommodation_type_id"
              name="accommodation_type_id"
              type="select"
              options={accommodationTypes}
              component={renderField}
              validate={Validators.required}
            />
          </div>

          {selectedType === 6 && (
            <>
              <p className="c-form__label">Details</p>
              <div className="c-form__row">
                <Field 
                  name="accommodation_other"
                  id="accommodation_other" 
                  component={renderField} 
                  type="textarea"
                  placeholder="Accomodation details"
                  validate={Validators.required}
                />
              </div>
            </>
          )}

          {selectedType !== 5 && (
            <>
              <p className={selectedType === 6 ? 'c-form__label u-mt-70px' : 'c-form__label'}>Address</p>
              <div className="c-form__row">
                <Field
                  id="address"
                  name="address"
                  type="text"
                  placeholder="Address"
                  component={renderField}
                />
              </div>
            </>
          )}

        </Card>

        {submitting 
          ? <Loading/>
          : <SubmitButton>Save Changes</SubmitButton>}
      </Form>
    </Page>
  )
}

AccomodationDetailsForm = reduxForm({
  form: 'AccomodationDetailsForm'
})(AccomodationDetailsForm)

const selector = formValueSelector('AccomodationDetailsForm');

function mapStateToProps(state) {
  return {
    accommodationTypes: state.accommodationTypes,
    selectedType: selector(state, 'accommodation_type_id'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccomodationDetailsForm)