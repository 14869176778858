import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { GET_SERVICES, STORE_SERVICE, DESTROY_SERVICE, UPDATE_SERVICE, SET_SELECTED_SERVICE } from '../actionTypes';

export function storeService(obj) {
  return function(dispatch) {
    return api
      .storeService(obj)
      .then(
        response => dispatch(storeServiceSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function storeServiceSuccess(response) {
  return {
    type: STORE_SERVICE,
    payload: response.data || {}
  }
}

export function getServices() {
  return function(dispatch) {
    return api
      .getServices()
      .then(
        response => dispatch(getServicesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getServicesSuccess(response) {
  return {
    type: GET_SERVICES,
    payload: response.data || {}
  }
}

export function destroyServiceById(serviceId) {
  return function(dispatch) {
    return api
      .destroyServiceById(serviceId)
      .then(
        response => dispatch(destroyServiceSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function destroyServiceSuccess(response) {
  return {
    type: DESTROY_SERVICE,
    payload: response.data || {}
  }
}

export function updateService(obj) {
  return function(dispatch) {
    return api
      .updateService(obj)
      .then(
        response => dispatch(updateServiceSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateServiceSuccess(response) {
  return {
    type: UPDATE_SERVICE,
    payload: response.data || {}
  }
}

export function setSelectedService(serviceId) {
  return {
    type: SET_SELECTED_SERVICE,
    payload: serviceId
  }
}
