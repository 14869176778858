import React, { Component } from 'react';
import { Page, Card } from 'react-onsenui';
import RenderToolbar from './utilities/RenderToolbar';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ReportActions from '../actions/reports';
import { 
  selectHousedUserTotals, 
  selectUnhousedUserTotals, 
  selectHousedUsersEth, 
  selectUnhousedUsersEth, 
  selectHousedUsersAgeGroups, 
  selectUnhousedUsersAgeGroups,
  selectHousedUsersAgeGroupTotals,
  selectUnhousedUsersAgeGroupTotals
} from '../reducers/reports';
import Loading from './utilities/Loading';

class Reporting extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedAgeGroup: ''
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    this.setState({loading: true});

    actions.getAccomodationTypeReport().then(response => {
      console.log(response);

      this.setState({loading: false});
    });
  }

  handleAgeGroupClicked = (e, chart) => {
    const ageGroups = ['0 - 18', '19 - 30', '31 - 50', '51+'];

    let index = chart[0] ? chart[0].index : null;
    if (index) {
      let selectedAgeGroup = ageGroups[index];
      this.setState({selectedAgeGroup});
    }
  }

  renderToolbar = () => <RenderToolbar title="Stats and Reporting" defaultBackButton={true}/>
  
  render() {
    const { housedUserTotals, unhousedUserTotals, housedUsersEth, unhousedUsersEth, housedUsersAgeGroups, unhousedUsersAgeGroups, housedUsersAgeGroupTotals, unhousedUsersAgeGroupTotals } = this.props;
    const { loading, selectedAgeGroup } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        {loading && <Loading />}
        
        {housedUserTotals && (
          <Card>
            <h3>Housed Users</h3>
            <Bar
              height={270}
              data={{
                labels: Object.keys(housedUserTotals),
                datasets: [{
                  label: 'Users',
                  data: Object.values(housedUserTotals),
                  backgroundColor: '#80A6A8',
                }]
              }}
            />
          </Card>
        )}

        {unhousedUserTotals && (
          <Card>
            <h3>Unhoused Users</h3>
            <Bar
              height={270}
              data={{
                labels: Object.keys(unhousedUserTotals),
                datasets: [{
                  label: 'Users',
                  data: Object.values(unhousedUserTotals),
                  backgroundColor: '#80A6A8',
                }]
              }}
            />
          </Card>
        )}

        {unhousedUsersEth && housedUsersEth && (
          <Card>
            <h3>Ethnicity</h3>
            <Bar
              height={280}
              data={{
                labels: Object.keys(unhousedUsersEth),
                datasets: [{
                  label: 'Unhoused Users',
                  data: Object.values(unhousedUsersEth),
                  backgroundColor: '#80A6A8',
                }, {
                  label: 'Housed Users',
                  data: Object.values(housedUsersEth),
                  backgroundColor: '#80a882',
                }]
              }}
            />
          </Card>
        )}

        {housedUsersAgeGroupTotals && unhousedUsersAgeGroupTotals && (
          <Card>
            <h3>Age Groups</h3>
            
            <Bar
              height={280}
              data={{
                labels: ['0 - 18', '19 - 30', '31 - 50', '51+'],
                datasets: [{
                  label: 'Unhoused Users',
                  data: Object.values(unhousedUsersAgeGroupTotals),
                  backgroundColor: '#80A6A8',
                }, {
                  label: 'Housed Users',
                  data: Object.values(housedUsersAgeGroupTotals),
                  backgroundColor: '#80a882',
                }]
              }}
              options={{
                events: ['click'],
                onClick: this.handleAgeGroupClicked
              }}
            />

            {housedUsersAgeGroups[selectedAgeGroup] && unhousedUsersAgeGroups[selectedAgeGroup] && (
              <Bar
                height={280}
                data={{
                  labels: Object.keys(housedUsersAgeGroups[selectedAgeGroup]),
                  datasets: [{
                    label: 'Unhoused Users',
                    data: Object.values(unhousedUsersAgeGroups[selectedAgeGroup]),
                    backgroundColor: '#80A6A8',
                  }, {
                    label: 'Housed Users',
                    data: Object.values(housedUsersAgeGroups[selectedAgeGroup]),
                    backgroundColor: '#80a882',
                  }]
                }}
              />
            )}
          </Card>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    housedUserTotals: selectHousedUserTotals(state),
    unhousedUserTotals: selectUnhousedUserTotals(state),
    housedUsersEth: selectHousedUsersEth(state),
    unhousedUsersEth: selectUnhousedUsersEth(state),
    housedUsersAgeGroups: selectHousedUsersAgeGroups(state),
    unhousedUsersAgeGroups: selectUnhousedUsersAgeGroups(state),
    housedUsersAgeGroupTotals: selectHousedUsersAgeGroupTotals(state),
    unhousedUsersAgeGroupTotals: selectUnhousedUsersAgeGroupTotals(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ReportActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);