import React, { useState } from 'react';
import { reduxForm, Field, Form, change, formValueSelector } from 'redux-form';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import { Page, Card, ProgressCircular, Row, Col } from 'react-onsenui';
import ons from "onsenui";
import { bindActionCreators } from 'redux';
import * as HouseActions from '../../actions/house';
import { connect } from "react-redux";
import DropzoneField from "../DropzoneField";
import RenderToolbar from '../utilities/RenderToolbar';
import { deleteFile, uploadFile } from '../../utils/s3';
import Uploading from '../utilities/Uploading';
import FormPhoto from '../FormPhoto';
import { uuid } from 'uuidv4';
import BathtubIcon from '@material-ui/icons/Bathtub';
import KingBedIcon from '@material-ui/icons/KingBed';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import cogoToast from 'cogo-toast';
import { STORE_HOUSE } from '../../actionTypes';

function HouseForm(props) {
  const { handleSubmit, formSubmitAction, actions, photos, title, buttonText, popPage, buildingTypeId, submitting } = props;

  const [uploading, setUploading] = useState(false);
  const [newPhotos, setNewPhotos] = useState([]);

  const isAndroid = ons.platform.isAndroid();

  const handleDrop = acceptedFiles => {
    setUploading(true);

    if (acceptedFiles.length) {
      uploadFile(acceptedFiles[0])
        .then(data => {
          let photo = data.Location.split('/').pop();

          // Initialise new photos with stored photo
          let newPhotos = [{id: uuid(), photo, primary: false, selected: false}];
  
          // If we have exisitng photos merge the two arrays
          if (photos !== undefined && photos.length > 0) {
            newPhotos = [...photos, ...newPhotos];
          }
  
          actions.change('HouseForm', 'house_photos', newPhotos);

          setNewPhotos([...newPhotos, ...newPhotos]);
          setUploading(false);
        })
        .catch(err => {
          console.log(err);
          setUploading(false);
        });
    } else {
      setUploading(false);
    }
  }

  // Pass in our action and wrap it in handle submit so that we can pop this form off the stack
  const handleFormSubmit = values => {    
    return formSubmitAction(values).then(response => {
      console.log(response);

      if (response.payload.message === 'success') {
        popPage();

        let toastMessage = response.payload.type === STORE_HOUSE
          ? 'House listing has been created'
          : 'House details have been updated';

        cogoToast.success(toastMessage);
      }
    });
  }

  const handlePhotoClicked = id => {
    let newPhotos = photos;

    newPhotos = newPhotos.map(item => {
      let newItem = {...item};
      if (newItem.id === id) {
        newItem.selected = !newItem.selected;
      }
      return newItem;
    });

    actions.change('HouseForm', 'house_photos', newPhotos);
  }

  const handleRemovePhotoClicked = (e, id) => {
    e.stopPropagation();

    let newPhotosVals = photos;

    newPhotosVals = newPhotosVals.filter(item => {
      let newItem = {...item};
      if (newItem.id !== id) {
        return newItem;
      }
    });

    let photoObj = newPhotos.find(p => p.id === id);
    if (photoObj) {
      deleteFile(photoObj.photo);
    }

    actions.change('HouseForm', 'house_photos', newPhotosVals);
  }

  const handleSetMainClicked = (e, id) => {
    e.stopPropagation();

    let newPhotos = photos;

    newPhotos = newPhotos.map(item => {
      let newItem = {...item};
      if (newItem.id !== id && newItem.primary) {
        newItem.primary = false;
      } else if (newItem.id === id) {
        newItem.primary = !newItem.primary;
      }

      return newItem;
    });

    actions.change('HouseForm', 'house_photos', newPhotos);
  }

  const handleBackPressed = () => {
    for (let newPhoto in newPhotos) {
      deleteFile(newPhoto.photo);
    }

    popPage();
  }
  
  return (
    <Page renderToolbar={() => <RenderToolbar title={title} backAction={handleBackPressed} />}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <h3>Details</h3>

          <p>What kind of building is this listing?</p>
          <div className="c-form__row">
            <Field 
              id="building_type_id"
              name="building_type_id"
              component={renderField}
              validate={Validators.required}
              placeholder="Building type"
              type="select"
              options={[
                { id: 1, name: 'Stand-Alone House' },
                { id: 2, name: 'Apartment' },
                { id: 3, name: 'Room' },
                { id: 4, name: 'Sleepout' }
              ]}
            />
          </div>

          <p>Provide a brief description about the house</p>
          <div className="c-form__row c-form__row--textarea">
            <Field 
              id="description"
              name="description"
              component={renderField}
              validate={Validators.required}
              placeholder="Description"
              type="textarea"
            />
          </div>

          <p>Where is the house located?</p>
          {buildingTypeId && buildingTypeId > 1 && (
            <div className="c-form__row">
              <Field 
                id="room_number"
                name="room_number"
                component={renderField}
                validate={Validators.required}
                placeholder="Room Number"
                type="text"
              />
            </div>
          )}
          
          <div className="c-form__row">
            <Field 
              id="street_address"
              name="street_address"
              component={renderField}
              validate={Validators.required}
              placeholder="Street Address"
              type="text"
            />
          </div>
          <div className="c-form__row">
            <Field 
              id="suburb"
              name="suburb"
              component={renderField}
              validate={Validators.required}
              placeholder="Suburb"
              type="text"
            />
          </div>
          <div className="c-form__row">
            <Field 
              id="postcode"
              name="postcode"
              component={renderField}
              validate={Validators.required}
              placeholder="Postcode"
              type="text"
            />
          </div>
          <div className="c-form__row">
            <Field 
              id="city"
              name="city"
              component={renderField}
              validate={Validators.required}
              placeholder="City"
              type="text"
            />
          </div>

          <p>Garaging</p>
          <div className="c-form__row">
            <Field 
              id="garaging"
              name="garaging"
              component={renderField}
              validate={Validators.required}
              placeholder="Garaging"
              type="text"
            />
          </div>

          <p>Driveway</p>
          <div className="c-form__row">
            <Field 
              id="driveway"
              name="driveway"
              component={renderField}
              validate={Validators.required}
              placeholder="Driveway"
              type="text"
            />
          </div>

          <p>Fencing</p>
          <div className="c-form__row">
            <Field 
              id="fencing"
              name="fencing"
              component={renderField}
              validate={Validators.required}
              placeholder="Fencing"
              type="text"
            />
          </div>

          <p>How much space does this house offer?</p>
          <div className="c-form__icon">
            <KingBedIcon className="u-mr-075"/>
            <div className="c-form__row">
              <Field 
                id="bedrooms"
                name="bedrooms"
                component={renderField}
                validate={Validators.required}
                placeholder="Number of Bedrooms"
                type="number"
              />
            </div>
          </div>
          <div className="c-form__icon">
            <BathtubIcon className="u-mr-075"/>
            <div className="c-form__row">
              <Field 
                id="bathrooms"
                name="bathrooms"
                component={renderField}
                validate={Validators.required}
                placeholder="Number of Bathrooms"
                type="number"
              />
            </div>
          </div>
          <div className="c-form__icon">
            <DriveEtaIcon className="u-mr-075"/>
            <div className="c-form__row">
              <Field 
                id="carparks"
                name="carparks"
                component={renderField}
                validate={Validators.required}
                placeholder="Number of Car Parks"
                type="number"
              />
            </div>
          </div>
          {/* <div className="c-form__icon">
            <SquareFootIcon className="u-mr-075"/>
            <div className="c-form__row">
              <Field 
                id="square_feet"
                name="square_feet"
                component={renderField}
                validate={Validators.required}
                placeholder="Square Feet"
                type="number"
              />
            </div>
          </div> */}

          <p>What facilities does this house have?</p>
          <div className="c-form__icon">
            <FireplaceIcon className="u-mr-075"/>
            <div className="c-form__row">
              <Field 
                id="heating_type_id"
                name="heating_type_id"
                component={renderField}
                validate={Validators.required}
                placeholder="Heating Type"
                type="select"
                options={[
                  { id: 1, name: 'Fireplace' },
                  { id: 2, name: 'Heat Pump' },
                  { id: 3, name: 'Central Heating' }
                ]}
              />
            </div>
          </div>
        </Card>

        <Card>
          <h3>Photos</h3>
          {uploading 
            ? <Uploading />
            : (
              <DropzoneField
                icon="ion-ios-camera"
                onDrop={handleDrop}
              />
            )}

          {photos.length > 0 && photos.map((item, i) => (
            <FormPhoto 
              key={i}
              photo={item.photo}
              newPhotos={newPhotos}
              primary={item.primary}
              selected={item.selected}
              onClick={() => handlePhotoClicked(item.id)}
              onRemovePhotoClicked={e => handleRemovePhotoClicked(e, item.id)}
              onSetMainClicked={e => handleSetMainClicked(e, item.id)}
            />
          ))}
        </Card>

        <Card>
          <h3>Compliance</h3>
          <Row>
            <Col width={'10%'} verticalAlign="center">
              <Field 
                id="rta_home_complient"
                name="rta_home_complient"
                component={renderField}
                type="checkbox"
              />
            </Col>
            <Col>
              <p>Does this property meet RTA and Home Compliance?</p>
            </Col>
          </Row>
        </Card>

        {submitting ? (
          <div className="u-text-center">
            <ProgressCircular indeterminate />
          </div>
        ) : (
          <button
            type="submit"
            className={isAndroid ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'} 
            disabled={submitting}
          >
            {buttonText}
          </button>
        )}
      </Form>
    </Page>
  )
}

HouseForm = reduxForm({
  form: 'HouseForm',
})(HouseForm)

const selector = formValueSelector('HouseForm');

function mapStateToProps(state) {
  return {
    photos: selector(state, 'house_photos') || [],
    buildingTypeId: selector(state, 'building_type_id'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...HouseActions, change }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HouseForm)