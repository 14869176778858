import apiClient from '../api/apiClient';
import axios from 'axios';

export function uploadFile(file, contentType) {
  return new Promise(async (resolve, reject) => {
    const key = `${Date.now().toString()}.${file.name.split('.').pop()}`;
    const urlRes = await apiClient.get(`/presigned-upload-url?file_path=${key}`);
    if (urlRes.status !== 200) reject(null);

    const axiosInstance = axios.create();
    axiosInstance.defaults.headers.common = {...urlRes.headers, 'Content-Type': contentType ? contentType : file.type};

    const uploadRes = await axiosInstance.put(urlRes.data, file);
  
    if (uploadRes.status !== 200) reject(null);

    resolve({ Location: key });
  });
}

export function deleteFile(fileName) {
  return new Promise(async (resolve, reject) => {
    const urlRes = await apiClient.delete(`/delete-object?file_path=${fileName}`);
    if (urlRes.status !== 200) reject(null);

    resolve({ Location: fileName });
  });
}

export function url(fileName) {
  return 'https://st-wact.s3.amazonaws.com/'+fileName;
}
