import React from 'react';

export default function CardImage(props) {
  const { image, title, subtitle } = props;

  return (
    <div className="c-card-image">
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      <img src={`https://st-wact.s3.amazonaws.com/${image}`}/>
    </div>
  )
}