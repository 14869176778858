import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { GET_ACCOMMODATION_TYPES } from '../actionTypes';

export function getAccommodationTypes() {
  return function(dispatch) {
    return api
      .getAccommodationTypes()
      .then(
        response => dispatch(getAccommodationTypesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function getAccommodationTypesSuccess(response) {
  return {
    type: GET_ACCOMMODATION_TYPES,
    payload: response.data || {}
  }
}