import React, { useState } from 'react';
import { reduxForm, Field, Form, formValueSelector, change } from 'redux-form';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import { Page, Card, ProgressCircular } from 'react-onsenui';
import { bindActionCreators } from 'redux';
import * as ServiceActions from '../../actions/services';
import { connect } from "react-redux";
import DropzoneField from "../DropzoneField";
import RenderToolbar from '../utilities/RenderToolbar';
import { uploadFile } from '../../utils/s3';
import Uploading from '../utilities/Uploading';
import cogoToast from 'cogo-toast';
import SubmitButton from '../utilities/SubmitButton';
import { STORE_SERVICE } from '../../actionTypes';

function ServiceForm(props) {
  const { handleSubmit, photo, title, buttonText, popPage, formAction, actions, serviceTypes } = props;
  
  const [submitting, setSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleFormSubmit = values => {
    setSubmitting(true);

    formAction(values).then(response => {
      console.log(response);

      if (response.payload.message === 'success') {
        popPage();

        let toastMessage = response.payload.type === STORE_SERVICE
          ? 'Service provider listing has been created'
          : 'Service provider details have been updated';

        cogoToast.success(toastMessage);

      } else {
        // TODO: Error handling
      }

      setSubmitting(false);
    });
  }

  const handleDrop = acceptedFiles => {
    setUploading(true);
    
    if (acceptedFiles.length) {
      uploadFile(acceptedFiles[0])
        .then(data => {
          actions.change('ServiceForm', 'photo', data.Location.split('/').pop());
          setUploading(false);
        })
        .catch(err => {
          console.log(err);
          setUploading(false);
        });
    } else {
      setUploading(false);
    }
  }
  
  return (
    <Page renderToolbar={() => <RenderToolbar title={title} backAction={popPage}/>}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <h3>Details</h3>

          <p>Provide a name and brief description about this provider?</p>
          <div className="c-form__row">
            <Field 
              id="name"
              name="name"
              component={renderField}
              validate={Validators.required}
              placeholder="Name"
              type="text"
            />
          </div>
          <div className="c-form__row">
            <Field 
              id="description"
              name="description"
              component={renderField}
              validate={Validators.required}
              placeholder="Description"
              type="text"
            />
          </div>

          <p>What are this service providers contact details?</p>
          <div className="c-form__row">
            <Field 
              id="email"
              name="email"
              component={renderField}
              validate={Validators.email}
              placeholder="Email Address"
              type="text"
            />
          </div>
          <div className="c-form__row">
            <Field 
              id="phone"
              name="phone"
              component={renderField}
              validate={Validators.required}
              placeholder="Phone Number"
              type="text"
            />
          </div>

          <p>What kind of service does this provider provide?</p>
          <div className="c-form__checkbox-group">
            <Field 
              id="service_types"
              name="service_types"
              component={renderField}
              validate={Validators.required}
              placeholder="Service Type"
              type="checkboxGroup"
              options={serviceTypes}
            />
          </div>
        </Card>

        <Card>
          {uploading ? (
              <Uploading />
            ) : (
              <DropzoneField
                icon="ion-ios-camera"
                onDrop={handleDrop}
              />
            )}

          {photo != '' && <img src={`https://st-wact.s3.amazonaws.com/${photo}`}/>}
        </Card>

        <div className="c-form__row">
          {submitting 
            ? <ProgressCircular indeterminate />
            : <SubmitButton>{buttonText}</SubmitButton>
          }
        </div>
      </Form>
    </Page>
  )
}

ServiceForm = reduxForm({
  form: 'ServiceForm',
  initialValues: { photo: '' }
})(ServiceForm)

const selector = formValueSelector('ServiceForm');

function mapStateToProps(state) {
  return {
    photo: selector(state, 'photo'),
    serviceTypes: state.serviceTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ServiceActions, change }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm)