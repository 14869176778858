import React from 'react';
import ons from 'onsenui';

export default function SubmitButton(props) {
  const isAndroid = ons.platform.isAndroid();
  
  return (
    <button 
      type="submit" 
      className={isAndroid ? 'button--material button--large--cta' : 'button--large--cta'} 
    >
      {props.children}
    </button>
  )
}