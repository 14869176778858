import React, { Component } from "react";
import { Page, List, ListItem, Icon, Button } from "react-onsenui";
import logo from "../images/logo.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../actions/user";

class SideBar extends Component {

  handleLogoutClicked = () => {
    const { actions } = this.props;

    actions.logout().then(response => {
      console.log(response);

      // navigator.popPage();
    });
  }

  renderRow = (row, i) => {
    return (
      <ListItem 
        key={i}
        onClick={row.onClick}
      >
        <Icon 
          size={{ default: 25, material: 25 }}
          icon={{ default: row.iosIcon, material: row.mdIcon }}
          fixedWidth={true}
        />
        <p>{row.text}</p>
      </ListItem>
    );
  }

  render() {
    const { onProfileClicked, onResourcesClicked, onReportingClicked, onServiceTypesClicked, onBuildingTypesClicked, onHeatingTypesClicked, isAdmin } = this.props;

    let navItems = [{
      text: 'Profile',
      iosIcon: 'ion-ios-person',
      mdIcon: 'md-account-circle',
      onClick: onProfileClicked,
    }];

    if (isAdmin) {
      navItems = [
        ...navItems, 
        {
          text: 'Reporting',
          iosIcon: 'md-chart',
          mdIcon: 'md-chart',
          onClick: onReportingClicked,
        },
        {
          text: 'Service Types',
          iosIcon: 'md-wrench',
          mdIcon: 'md-wrench',
          onClick: onServiceTypesClicked,
        },
        {
          text: 'Building Types',
          iosIcon: 'md-city',
          mdIcon: 'md-city',
          onClick: onBuildingTypesClicked,
        },
        {
          text: 'Heating Types',
          iosIcon: 'md-fire',
          mdIcon: 'md-fire',
          onClick: onHeatingTypesClicked,
        }
      ];
    }

    navItems = [
      ...navItems, {
        text: 'Complaints or compliments?',
        iosIcon: 'md-email',
        mdIcon: 'md-email',
        onClick: () => { window.location.href='mailto:housing@wact.org.nz'; },
      }
    ];

    return(
      <Page className="c-sidebar">
        <div className="c-sidebar-logo">
          <img src={logo}/>
        </div>

        <List
          dataSource={navItems}
          renderRow={this.renderRow}
        />

        <div className="c-sidebar__button-group">
          <Button onClick={this.handleLogoutClicked} className="button--large--cta">Logout</Button>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.admin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);