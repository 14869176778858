import React, { useState } from 'react';
import { reduxForm, Form, Field, formValueSelector, change } from 'redux-form';
import { renderField } from '../RenderField';
import Loading from '../utilities/Loading';
import * as Validators from '../Validators';
import { Card, Page } from 'react-onsenui';
import { STORE_ACCOMMODATION_SITE, UPDATE_ACCOMMODATION_SITE } from '../../actionTypes';
import cogoToast from 'cogo-toast';
import { connect } from 'react-redux';
import RenderToolbar from '../utilities/RenderToolbar';
import SubmitButton from '../utilities/SubmitButton';
import { bindActionCreators } from 'redux';
import { uploadFile, deleteFile } from '../../utils/s3';
import DropzoneField from '../DropzoneField';
import FormDocument from '../FormDocument';

function AccommodationSitesForm(props) {
  const { submitting, handleSubmit, documents, formAction, popPage, title, buttonText, actions } = props;
  const [uploading, setUploading] = useState();
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const handleFormSubmit = values => {
    return formAction(values).then(response => {
      console.log(response);
   
      if (response.type === STORE_ACCOMMODATION_SITE) {
        cogoToast.success('Site created');
        popPage();
      }

      if (response.type === UPDATE_ACCOMMODATION_SITE) {
        cogoToast.success('Site details updated');
        popPage();
      }
    });
  }

  const handleDrop = acceptedFiles => {
    setUploading(true);
    
    if (acceptedFiles.length) {
      uploadFile(acceptedFiles[0], 'application/pdf')
        .then(data => {
          let newDocument = data.Location.split('/').pop();
          let newDocuments = documents && documents.length > 0 
            ? [...documents, newDocument] 
            : [newDocument];

          actions.change('AccommodationSitesForm', 'documents', newDocuments);
          setUploadedDocuments([...uploadedDocuments, newDocument]);
          setUploading(false);
        })
        .catch(error => {
          console.log(error);
          setUploading(false);
        });
    } else {
      setUploading(false);
    }
  }

  const handleRemoveDocumentClicked = document => {
    let newDocuments = documents.filter(d => d !== document);

    console.log(uploadedDocuments, document);

    // If we are not storing this file in our db yet, remove it from s3
    if (uploadedDocuments.includes(document)) {
      deleteFile(document)
        .then(data => console.log(data))
        .catch(error => console.log(error));
    }

    actions.change('AccommodationSitesForm', 'documents', newDocuments);
  }

  const handleBackPressed = () => {
    for (let document in uploadedDocuments) {
      deleteFile(document)
        .then(data => console.log(data))
        .catch(error => console.log(error));
    }
    popPage();
  }

  return (
    <Page renderToolbar={() => <RenderToolbar title={title} backAction={handleBackPressed}/>}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <h3>Site Details</h3>
          <p>Provide a name and the address of this site?</p>
          <div className="c-form__row">
            <Field 
              id="name"
              name="name"
              component={renderField}
              validate={Validators.required}
              placeholder="Name"
              type="text"
            />
          </div>
          <div className="c-form__row">
            <Field 
              id="address"
              name="address"
              component={renderField}
              validate={Validators.required}
              placeholder="Address"
              type="text"
            />
          </div>

          <h3>Information</h3>
          <p>Provide information about this site's Covid response protocols.</p>
          <div className="c-form__row u-h-200">
            <Field 
              name="info"
              id="info" 
              component={renderField} 
              type="textarea"
              placeholder="Enter information here"
            />
          </div>
        </Card>

        <Card>
          {uploading 
            ? <Loading/>
            : (
              <DropzoneField 
                onDrop={handleDrop} 
                icon="ion-ios-cloud-upload" 
                buttonText="Upload a Document"
                accept=".pdf"
              />
            )}

          {documents && documents.length > 0 && documents.map((document, i) => (
            <FormDocument
              key={i} 
              document={document}
              newDocuments={uploadedDocuments}
              onRemoveDocumentClicked={() => handleRemoveDocumentClicked(document)}
            />
          ))}
        </Card>

        {submitting 
          ? <Loading/>
          : <SubmitButton className="button--large--cta">{buttonText}</SubmitButton>
        }
      </Form>
    </Page>
  );
}

AccommodationSitesForm = reduxForm({
  form: 'AccommodationSitesForm'
})(AccommodationSitesForm)

const selector = formValueSelector('AccommodationSitesForm');

function mapStateToProps(state) {
  return {
    documents: selector(state, 'documents')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationSitesForm)