import React from 'react';
import { reduxForm, Form, change, submit, formValueSelector } from 'redux-form';
import DropzoneField from '../DropzoneField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadFile } from '../../utils/s3';
import FormPhoto from '../FormPhoto';
import { uuid } from 'uuidv4';


function DocumentsForm(props) {
  const { idDocs, policeVettDocs, creditCheckDocs, actions, handleSubmit } = props;

  const handleDrop = (file, field, documents) => {
    if (file.length) {
      uploadFile(file[0])
        .then(data => {
          // Initialise new documents with stored photo
          let newDocuments = [{id: uuid(), document: data.Location, selected: false}];
  
          // If we have exisitng documents merge the two arrays
          if (documents !== undefined && documents.length > 0) {
            newDocuments = [...documents, ...newDocuments];
          }
  
          actions.change('DocumentsForm', field, newDocuments);

          actions.submit('DocumentsForm');
        })
        .catch(err => console.log(err));
    }
  }

  const handleDocumentClicked = (id, field, documents) => {
    let newDocuments = documents;

    newDocuments = newDocuments.map(item => {
      let newItem = {...item};
      if (newItem.id === id) {
        newItem.selected = !newItem.selected;
      }
      return newItem;
    });

    actions.change('DocumentsForm', field, newDocuments);
  }

  const handleRemoveDocumentClicked = (e, id, field, documents) => {
    e.stopPropagation();

    let newDocuments = documents;

    newDocuments = newDocuments.filter(item => {
      let newItem = {...item};
      if (newItem.id !== id) {
        return newItem;
      }
    });

    actions.change('DocumentsForm', field, newDocuments);    
  }

  return (
    <Form onSubmit={handleSubmit} className="c-form">
      <p>Photo ID<span className="c-form__text--required"> - Required</span></p>
      <DropzoneField 
        icon="ion-ios-camera"
        onDrop={acceptedFiles => handleDrop(acceptedFiles, 'photo_identification_documents', idDocs)}
      />
      {idDocs && idDocs.length > 0 && idDocs.map((d, i) => (
        <FormPhoto 
          key={i}
          photo={d.document}
          selected={d.selected}
          onClick={() => handleDocumentClicked(d.id, 'photo_identification_documents', idDocs)}
          onRemovePhotoClicked={e => handleRemoveDocumentClicked(e, d.id, 'photo_identification_documents', idDocs)}
        />
      ))}

      <p>Credit Check<span className="c-form__text--required"> - Required</span></p>
      <DropzoneField 
        icon="ion-ios-camera"
        onDrop={acceptedFiles => handleDrop(acceptedFiles, 'credit_check_documents', creditCheckDocs)}
      />
      {creditCheckDocs && creditCheckDocs.length > 0 && creditCheckDocs.map((d, i) => (
        <FormPhoto 
          key={i}
          photo={d.document}
          selected={d.selected}
          onClick={() => handleDocumentClicked(d.id, 'credit_check_documents', creditCheckDocs)}
          onRemovePhotoClicked={e => handleRemoveDocumentClicked(e, d.id, 'credit_check_documents', creditCheckDocs)}
        />
      ))}

      <p>Police Vetting</p>
      <DropzoneField 
        icon="ion-ios-camera"
        onDrop={acceptedFiles => handleDrop(acceptedFiles, 'police_vetting_documents', policeVettDocs)}
      />
      {policeVettDocs && policeVettDocs.length > 0 && policeVettDocs.map((d, i) => (
        <FormPhoto 
          key={i}
          photo={d.document}
          selected={d.selected}
          onClick={() => handleDocumentClicked(d.id, 'police_vetting_documents', policeVettDocs)}
          onRemovePhotoClicked={e => handleRemoveDocumentClicked(e, d.id, 'police_vetting_documents', policeVettDocs)}
        />
      ))}
    </Form>
  );
}


function onChange(values, dispatch, props, previousValues) {
  if (values.photo_identification_documents && previousValues.photo_identification_documents) {
    if (values.photo_identification_documents.length != previousValues.photo_identification_documents.length) {
      dispatch(submit('DocumentsForm'))
    }
  }

  if (values.credit_check_documents && previousValues.credit_check_documents) {
    if (values.credit_check_documents.length != previousValues.credit_check_documents.length) {
      dispatch(submit('DocumentsForm'))
    }
  }

  if (values.police_vetting_documents && previousValues.police_vetting_documents) {
    if (values.police_vetting_documents.length != previousValues.police_vetting_documents.length) {
      dispatch(submit('DocumentsForm'))
    }
  }
}

DocumentsForm = reduxForm({
  form: 'DocumentsForm',
  onChange
})(DocumentsForm)

const selector = formValueSelector('DocumentsForm');

function mapStateToProps(state) {  
  return {
    idDocs: selector(state, 'photo_identification_documents'),
    policeVettDocs: selector(state, 'police_vetting_documents'),
    creditCheckDocs: selector(state, 'credit_check_documents')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change, submit }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsForm);