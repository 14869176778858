import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { 
  GET_BUILDING_TYPES,
  STORE_BUILDING_TYPE,
  UPDATE_BUILDING_TYPE,
  DESTROY_BUILDING_TYPE
} from '../actionTypes';

export function getBuildingTypes() {
  return function(dispatch) {
    return api
      .getBuildingTypes()
      .then(
        response => dispatch(getBuildingTypesSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function getBuildingTypesSuccess(response) {
  return {
    type: GET_BUILDING_TYPES,
    payload: response.data || {}
  }
}

export function storeBuildingType(obj) {
  return function(dispatch) {
    return api
      .storeBuildingType(obj)
      .then(
        response => dispatch(storeBuildingTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function storeBuildingTypeSuccess(response) {
  return {
    type: STORE_BUILDING_TYPE,
    payload: response.data || {}
  }
}

export function updateBuildingType(obj) {
  return function(dispatch) {
    return api
      .updateBuildingType(obj)
      .then(
        response => dispatch(updateBuildingTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function updateBuildingTypeSuccess(response) {
  return {
    type: UPDATE_BUILDING_TYPE,
    payload: response.data || {}
  }
}

export function destroyBuildingType(id) {
  return function(dispatch) {
    return api
      .destroyBuildingType(id)
      .then(
        response => dispatch(destroyBuildingTypeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function destroyBuildingTypeSuccess(response) {
  return {
    type: DESTROY_BUILDING_TYPE,
    payload: response.data || {}
  }
}