import React, { useState } from "react";
import { Form, Field, reduxForm, formValueSelector } from "redux-form";
import { renderField } from "../RenderField";
import { selectUser, selectBookableUsers } from "../../reducers/users";
import * as Validators from "../Validators";
import { connect } from "react-redux";
import { Page, Card } from 'react-onsenui';
import RenderToolbar from "../utilities/RenderToolbar";
import { bindActionCreators } from "redux";
import * as AppointmentActions from "../../actions/appointment";
import SubmitButton from '../utilities/SubmitButton';
import Loading from '../utilities/Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import { setHours, setMinutes } from "date-fns";


function AppointmentForm(props) {
  const { availableDays, availableTimes, appointments, popPage, bookableUsers, selectedId, submitting, handleSubmit, actions, user } = props;

  const [date, setDate] = useState('');
  const [showDateError, setShowDateError] = useState(false);

  const [time, setTime] = useState('');
  const [showTimeError, setShowTimeError] = useState(false);

  const handleFormSubmit = values => {
    if (date === '') {
      setShowDateError(true);
      return;
    }

    if (time === '') {
      setShowTimeError(true);
      return;
    }

    const dateString = moment(date).format('YYYY-MM-DD');
    const timeString = moment(time).format('HH:mm:ss');

    return actions.storeAppointment({
      ...values,
      date: `${dateString} ${timeString}`,
      user_id: user.id,
      staff_user_id: selectedId
    }).then(response => {
      console.log(response);

      if (response.type === 'STORE_APPOINTMENT') {
        popPage();
      }
    });
  }

  const filterDates = dateTime => {
    const dateString = moment(dateTime).format('YYYY-MM-DD');
    const day = dateTime.getDay();

    const todaysAvailableTimes = availableTimes[day] ? availableTimes[day] : [];
    const bookedTimeslots = appointments.filter(a => moment(a).format('YYYY-MM-DD') == dateString);

    // The day is not in the past, is bookable and has not been booked yet
    return dateTime > Date.now() && availableDays.includes(day) && todaysAvailableTimes.length > bookedTimeslots.length;
  }

  const filterTimes = dateTime => {
    const time = new Date(dateTime).toTimeString();
    const day = new Date(date).getDay();
    const dateString = moment(date).format('YYYY-MM-DD');
    
    const todaysAppointmentTimes = appointments.filter(a => moment(a).format('YYYY-MM-DD') == dateString).map(a => new Date(a).toTimeString());
    const todaysAvailableTimes = availableTimes[day] ? availableTimes[day].map(d => new Date(d.replace(' ', 'T')).toTimeString()) : [];

    // The timeslot is bookable and has not been booked yet
    return todaysAvailableTimes.includes(time) && !todaysAppointmentTimes.includes(time);
  }

  const renderToolbar = () => <RenderToolbar title="Book an Appointment" backAction={popPage}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <p>Who would you like to book an appointment with</p>
          <div className="c-form__row">
            <Field 
              id="staff_user_id"
              name="staff_user_id"
              component={renderField}
              type="select"
              placeholder="Please Select"
              options={bookableUsers}
            />
          </div>
            
          {selectedId && (
            <>
              <p>Please choose an available day</p>
              <DatePicker
                selected={date}
                dateFormat="MMMM d, yyyy"
                placeholderText="Select a date"
                onChange={date => setDate(date)}
                filterDate={filterDates}
                inline
              />
              {showDateError && <div className="c-form__error"><i>Please select a date</i></div>}

              {date !== '' && (
                <>
                  <p>Please choose an available timeslot</p>
                  <DatePicker
                    selected={time}
                    dateFormat="h:mm aa"
                    placeholderText="Select a timeslot"
                    onChange={time => setTime(time)}
                    filterTime={filterTimes}
                    // injectTimes={[
                    //   setHours(setMinutes(date, 45), 10),
                    //   setHours(setMinutes(date, 45), 13)
                    // ]}
                    showTimeSelect
                    showTimeSelectOnly
                    inline
                  />
                  {showTimeError && <div className="c-form__error"><i>Please select a timeslot</i></div>}
                </>
              )}
            </>
          )}

          <p>What is this appointment about</p>
          <div className="c-form__row u-mb-50px">
            <Field 
              name="details"
              id="details" 
              component={renderField} 
              type="textarea" 
              placeholder="Appointment Details"
              validate={[Validators.required]}
            />
          </div>
        </Card>
        {submitting 
          ? <Loading/>
          : <SubmitButton>Book Appointment</SubmitButton>
        }
      </Form>
    </Page>
  );
}

AppointmentForm = reduxForm({
  form: 'AppointmentForm',
})(AppointmentForm)

const selector = formValueSelector('AppointmentForm');

function mapStateToProps(state) {
  const selectedId = selector(state, 'staff_user_id');
  let bookableUsers = selectBookableUsers(state)
  let availableDays = [];
  let appointments = [];
  let availableTimes = {};

  if (bookableUsers.length > 0) {
    bookableUsers = bookableUsers.map(u => ({
      id: u.id, 
      name: `${u.first_name} ${u.last_name}`, 
    }));
  }
  
  if (selectedId) {
    const selectedUser = selectUser(state, selectedId);

    // Users available week days
    availableDays = selectedUser.appointment_availabilities.map(a => a.day);
    
    // Group timeslots by day of the week
    selectedUser.appointment_availabilities.forEach(a => {
      if (availableTimes[a.day]) {
        availableTimes[a.day].push(a.time);
      } else {
        availableTimes[a.day] = [a.time];
      }
    });

    // Get dates user has been booked for
    appointments = state.appointments.filter(a => a.staff_user_id == selectedUser.id).map(a => a.date);
  }

  return {
    user: state.user,
    selectedId,
    bookableUsers,
    appointments,
    availableDays,
    availableTimes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...AppointmentActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentForm)