import React from 'react';
import { ProgressCircular } from 'react-onsenui';

export default function Uploading() {
  return (
    <div className="c-uploading">
      <p>Uploading</p>
      <ProgressCircular indeterminate/>
    </div>
  )
}