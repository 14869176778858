import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Page, List, ProgressCircular } from 'react-onsenui';
import * as HouseActions from '../../actions/house';
import { bindActionCreators } from 'redux';
import HouseListing from './HouseListing';
import ListingItem from '../ListingItem';
import HouseForm from './HouseForm';
import BathtubIcon from '@material-ui/icons/Bathtub';
import KingBedIcon from '@material-ui/icons/KingBed';

class Houses extends Component {

  componentDidMount() {
    const { actions } = this.props;

    actions.getHouses().then(response => console.log(response));
  }

  handleViewHouseClicked = house => {
    const { pushPage, actions } = this.props;

    actions.setSelectedHouse(house.id);

    pushPage(HouseListing, house.id, { houseId: house.id });
  }

  handleAddHouseClicked = () => {    
    const { actions, pushPage } = this.props;
    
    const props = { 
      formSubmitAction: actions.storeHouse,
      title: 'Add House',
      buttonText: 'Add This Listing',
      initialValues: { photos: [] }
    }

    pushPage(HouseForm, 'HouseForm', props);
  }

  renderRow = (row, i) => {
    const { house_photos } = row;

    const primaryPhoto = house_photos && house_photos.length ? house_photos.filter(photo => photo.primary)[0] : null;

    return (
      <ListingItem
        key={i}
        onClick={() => this.handleViewHouseClicked(row)}
        image={primaryPhoto ? primaryPhoto.photo : ''}
        title={row.street_address}
        subtitle={row.suburb}
        icons={[
          {amount: row.bathrooms, icon: BathtubIcon},
          {amount: row.bedrooms,  icon: KingBedIcon}
        ]}
        modifier="nodivider"
      />
    );
  }
  
  render() {
    const { houses, isAdmin } = this.props;

    return (
      houses ? (
        <Page>
          {isAdmin && (
            <Button
              className="button--large--cta u-mb-1" 
              onClick={this.handleAddHouseClicked}
            >
              Add House Listing
            </Button>
          )}
          <List
            dataSource={houses}
            renderRow={this.renderRow}
          />
        </Page>
      ) : (
        <div className="page-loading">
          <ProgressCircular indeterminate />
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    houses: state.houses.items,
    isAdmin: state.user.admin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...HouseActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Houses)