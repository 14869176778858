import { GET_ACCOMODATION_TYPE_REPORT } from '../actionTypes';
import { createSelector } from 'reselect';

const initialState = {
  accomodationTypeReport: {}
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_ACCOMODATION_TYPE_REPORT:
      return getAccomodationTypeReport(state, payload);
    default:
      return state;
  }
}

function getAccomodationTypeReport(state, payload) {
  return {
    ...state,
    accomodationTypeReport: payload.data
  };
}

export const selectAccomodationTypeReport = state => state.reports.accomodationTypeReport;

export const selectHousedUserTotals = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.housed_user_totals) {
    return accomodationTypeReport.housed_user_totals;
  }
});

export const selectUnhousedUserTotals = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.unhoused_user_totals) {
    return accomodationTypeReport.unhoused_user_totals;
  }
});

export const selectHousedUsersEth = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.housed_users_eth) {
    return accomodationTypeReport.housed_users_eth;
  }
});

export const selectUnhousedUsersEth = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.unhoused_users_eth) {
    return accomodationTypeReport.unhoused_users_eth;
  }
});

export const selectHousedUsersAgeGroups = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.housed_users_age_groups) {
    return accomodationTypeReport.housed_users_age_groups;
  }
});

export const selectUnhousedUsersAgeGroups = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.unhoused_users_age_groups) {
    return accomodationTypeReport.unhoused_users_age_groups;
  }
});

export const selectHousedUsersAgeGroupTotals = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.housed_users_age_group_totals) {
    return accomodationTypeReport.housed_users_age_group_totals;
  }
});

export const selectUnhousedUsersAgeGroupTotals = createSelector([selectAccomodationTypeReport], accomodationTypeReport => {
  if (accomodationTypeReport.unhoused_users_age_group_totals) {
    return accomodationTypeReport.unhoused_users_age_group_totals;
  }
});


export default reducer;