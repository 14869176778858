import React from 'react';
import { Page, Card } from 'react-onsenui';
import { reduxForm, Form, Field } from 'redux-form';
import RenderToolbar from '../utilities/RenderToolbar';
import SubmitButton from '../utilities/SubmitButton';
import Loading from '../utilities/Loading';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import { UPDATE_SERVICE_TYPE } from '../../actionTypes';
import cogoToast from 'cogo-toast';


function ServiceTypeForm({ handleSubmit, submitting, formAction, navigator }) {

  const handleFormSubmit = values => {
    return formAction(values).then(response => {
      console.log(response);
      
      let successMessage = 'Service type added';
      if (response.type === UPDATE_SERVICE_TYPE) {
        successMessage = 'Service type updated'
      }

      cogoToast.success(successMessage);

      navigator.popPage();
    });
  }

  const renderToolbar = () => <RenderToolbar title="Service Type" defaultBackButton={true}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <p>Name</p>
          <div className="c-form__row">
            <Field 
              name="name"
              id="name" 
              component={renderField} 
              type="text" 
              placeholder="Service type name"
              validate={Validators.required}
            />
          </div>
        </Card>

        {submitting 
          ? <Loading />
          : <SubmitButton>Save</SubmitButton>}
      </Form>
    </Page>
  );
}

export default reduxForm({
  form: 'ServiceTypeForm'
})(ServiceTypeForm);