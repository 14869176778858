import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { STORE_APPLICATION } from '../actionTypes';

export function storeApplication(obj) {
  return function(dispatch) {
    return api
      .storeApplication(obj)
      .then(
        response => dispatch(storeApplicationSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function storeApplicationSuccess(response) {
  return {
    type: STORE_APPLICATION,
    payload: response.data || {}
  }
}