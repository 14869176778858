import Profile from "../Profile";
import Appointments from "../appointments/Appointments";
import Houses from "../houses/Houses";
import Services from "../service-directory/Services";
import Covid from '../Covid';
import Home from "../Home";

const tabs = [
  {
    name: 'home',
    title: 'Home',
    // icon: 'ion-ios-person, material:md-account-circle',
    icon: 'md-collection-video, material:md-chart',
    component: Home
  },
  {
    name: 'appointements',
    title: 'Appointments',
    icon: 'ion-ios-calendar, material:md-calendar',
    component: Appointments
  },
  {
    name: 'house-listings',
    title: 'House Listings',
    icon: 'ion-ios-home, material:md-home',
    component: Houses
  },
  {
    name: 'service-directory', 
    title: 'Services',
    icon: 'ion-ios-hammer, material:md-wrench',
    component: Services
  },
  {
    name: 'covid',
    title: 'Covid',
    icon: 'ion-ios-information-circle, material:md-info',
    component: Covid
  }
];

export default tabs;