import React from 'react';
import { Field } from 'redux-form';
import { renderField } from '../../RenderField';
import * as Validators from '../../Validators';

export function StepTwo({errors}) {
  return (
    <div>
      <h2>Tell us some more</h2>
      <p>What is your gender?</p>
      <div className="c-form__row">
        <Field 
          name="gender"
          id="gender" 
          component={renderField} 
          type="select" 
          placeholder="Gender"
          options={[
            {id: 'Male', name: 'Male'},
            {id: 'Female', name: 'Female'},
            {id: 'Gender Diverse', name: 'Gender Diverse'},
          ]}
          validate={[Validators.required]}
        />
      </div>
      
      <p>What is your age?</p>
      <div className="c-form__row">
        <Field 
          name="age"
          id="age" 
          component={renderField} 
          type="number" 
          placeholder="Age"
          validate={[Validators.required]}
        />
        {errors.age !== undefined && <div className="c-form__error"><i>{errors.age[0]}</i></div>}
      </div>

      <p>What is your ethinicity?</p>
      <div className="c-form__row">
        <Field 
          name="ethnicity"
          id="ethnicity" 
          component={renderField} 
          type="select" 
          placeholder="Ethnicity"
          options={[
            {id: 'Maori', name: 'Maori'},
            {id: 'NZ European', name: 'NZ European'},
            {id: 'Pacific', name: 'Pacific Islander'}
          ]}
          validate={[Validators.required]}
        />
      </div>
    </div>
  );
}