import React from 'react';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
// import { sendError } from '../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      loading: false,
      error: null, 
      errorInfo: null 
    };
  }
  
  // componentDidCatch(error, errorInfo) {
  //   this.setState({loading: true});

  //   sendError({error: error.toString(), errorInfo: errorInfo})
  //     .then(response => this.setState({loading: false}))
  //     .catch(error => this.setState({loading: false}));

  //   this.setState({
  //     error: error,
  //     errorInfo: errorInfo
  //   });
  // }
  
  render() {
    if (this.state.errorInfo) {
      return (
        <section className="page">
          <Container className="pt-4">
            <h2>Something went wrong... the sadness</h2>

            {this.state.loading ?
              <FontAwesomeIcon icon="spinner" spin />
            :
              <>
                <details className="mb-4" style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error && this.state.error.toString()}
                  <br/>
                  {this.state.errorInfo.componentStack}
                </details>

                <Button color="primary" onClick={() => window.location.reload()}>Refresh</Button>
              </>
            }
          </Container>
        </section>
      );
    }
    // Render children if there's no error
    return this.props.children;
  }  
}