import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LOGIN, AJAX_CALL_ERROR } from '../../actionTypes';
import * as UserActions from '../../actions/user';
import LoginForm from './LoginForm';
import Register from '../register/Register';
import { SubmissionError } from 'redux-form';
import { Page, Toolbar, Card, Button } from 'react-onsenui';
import ResetPassword from './ResetPassword';
import logo from '../../images/logo.png';


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginFailed: false,
    }
  }

  handleSubmit = values => {
    const { actions } = this.props;

    return actions.login({
      email: values.email,
      password: values.password
    }).then(response => {
      console.log(response);
      
      if (response.type === LOGIN) {
        actions.getAuthUser().then(response => {
          console.log(response);

          this.setState({submitting: false});
        });
        // new ServiceWorker().init();
      } else if (response.type === AJAX_CALL_ERROR) {
        throw new SubmissionError({
          _error: response.payload.message
        });
      } else {
        this.setState({loginFailed: true});
      }
    });
  }

  renderToolbar = () => {
    return (
      <Toolbar>
        <div className="center">
          Login
        </div>
      </Toolbar>
    );
  }

  handleSignUpClicked = () => {
    const { navigator } = this.props;

    navigator.pushPage({Component: Register})
  }

  handleResetPasswordClicked = () => {
    const { navigator } = this.props;

    navigator.pushPage({Component: ResetPassword})
  }

  render() {
    const { loginFailed } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Card>
          <div className="c-login__logo">
            <img className="img-fluid" src={logo} />
          </div>

          <div className="u-text-center">
            <h4>Do you already have an account with us?</h4>
          </div>
          
          <LoginForm onSubmit={this.handleSubmit}/>

          <p className="c-login__reset"><a onClick={this.handleResetPasswordClicked}>Forgot your password?</a></p>
        </Card>

        {loginFailed && (
          <div className="u-text-center u-mt-1">
            <i>Your username or password is incorrect</i>
          </div>
        )}

        <Card className="u-mt-1">
          <div className="u-text-center u-mb-1">
            <h4>Dont have an account with us?</h4>
          </div>
          <Button className="button--large--cta" onClick={this.handleSignUpClicked}>Sign Up</Button>
        </Card>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)