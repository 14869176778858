import React, { Component } from 'react';
import { Page, Card } from 'react-onsenui';
import RenderToolbar from './utilities/RenderToolbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Resources extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  renderToolbar = () => <RenderToolbar title="Resources" defaultBackButton={true}/>
  
  render() {
    return (
      <Page renderToolbar={this.renderToolbar}>
        <Card>
          <h2>Resources</h2>
        </Card>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resources);