import { DESTROY_HOUSE, GET_HOUSES, STORE_HOUSE, UPDATE_HOUSE, SET_SELECTED_HOUSE } from '../actionTypes';
import { createSelector } from 'reselect';

const initialState = {
  items: [],
  selectedHouseId: 0,
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_HOUSES:
      return getHouses(state, payload);
    case STORE_HOUSE:
      return storeHouse(state, payload);
    case DESTROY_HOUSE:
      return destroyHouse(state, payload);
    case UPDATE_HOUSE:
      return updateHouse(state, payload)
    case SET_SELECTED_HOUSE:
      return setSelectedHouse(state, payload)
    default:
      return state;
  }
}

function getHouses(state, payload) {
  return {
    ...state,
    items: payload.houses,
  };
}

function storeHouse(state, payload) {
  return {
    ...state,
    items: [...state.items, payload.house],
  };
}

function destroyHouse(state, payload) {
  return {
    ...state,
    items: state.items.filter(item => item.id != payload.houseId)
  }
}

function updateHouse(state, payload) {
  return { 
    ...state,
    items: state.items.map(item => item.id == payload.house.id ? payload.house : item)
  }
}

function setSelectedHouse(state, payload) {
  return {
    ...state,
    selectedHouseId: payload
  }
}

export const selectHouses = state => state.houses.items;
export const selectHouseId = state => state.houses.selectedHouseId;

export const selectHouse = createSelector([selectHouses, selectHouseId], (houses, houseId) => {
  return houses.filter(h => h.id === houseId)[0];
});

export const selectHousePhotos = createSelector([selectHouse], house => {
  if (house && house.house_photos.length > 0) {
    return house.house_photos;
  }
});

export const selectPrimaryHousePhoto = createSelector([selectHousePhotos], photos => {
  if (photos && photos.length) {
    const photo = photos.filter(i => i.primary === true)[0];

    if (photo) return photo;
  }
});

export default reducer;