import * as api from '../api/api';
import { GET_APPOINTMENTS, STORE_APPOINTMENT, UPDATE_APPOINTMENT, DESTROY_APPOINTMENT } from '../actionTypes';
import ajaxCallError from './ajaxCallError';

export function storeAppointment(obj) {
  return function(dispatch) {
    return api
      .storeAppointment(obj)
      .then(
        response => dispatch(storeAppointmentSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function storeAppointmentSuccess(response) {
  return {
    type: STORE_APPOINTMENT,
    payload: response.data || {}
  }
}

export function getAppointments(email, password) {
  return function(dispatch) {
      return api
        .getAppointments(email, password)
        .then(
          response =>  dispatch(getAppointmentsSuccess(response)),
          error => dispatch(ajaxCallError(error))
        );
  }
}

function getAppointmentsSuccess(response) {
  return {
    type: GET_APPOINTMENTS,
    payload: response.data || {}
  }
}

export function destroyAppointmentById(id) {
  return function(dispatch) {
    return api
      .destroyAppointmentById(id)
      .then(
        response => dispatch(destroyAppointmentSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function destroyAppointmentSuccess(response) {
  return {
    type: DESTROY_APPOINTMENT,
    payload: response.data || {}
  }
}

export function updateAppointment(obj) {
  return function(dispatch) {
    return api
      .updateAppointment(obj)
      .then(
        response => dispatch(updateAppointmentSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function updateAppointmentSuccess(response) {
  return {
    type: UPDATE_APPOINTMENT,
    payload: response.data || {}
  }
}