import React from 'react';
import { Toolbar, BackButton } from 'react-onsenui';

export default function RenderToolbar(props) {
  const { defaultBackButton, backAction, title } = props;

  return (
    <Toolbar>
      {backAction && (
        <div className="left">
          <BackButton onClick={backAction}>Back</BackButton>
        </div>
      )}
      {defaultBackButton && (
        <div className="left">
          <BackButton>Back</BackButton>
        </div>
      )}
      {title && (
        <div className="center">
          {title}
        </div>
      )}
    </Toolbar>
  )
}