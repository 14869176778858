// User
export const AUTH_CHECK = 'AUTH_CHECK';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const GET_USER = 'GET_USER';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_CONFIRM = 'PASSWORD_RESET_CONFIRM';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_MOVING_CHECKLIST = 'UPDATE_USER_MOVING_CHECKLIST';
export const UPDATE_USER_APPOINTMENT_AVAILABILITY = 'UPDATE_USER_APPOINTMENT_AVAILABILITY';
export const UPDATE_USER_ACCOMODATION_DETAILS = 'UDPATE_USER_ACCOMODATION_DETAILS';

// Login
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';

// Appointments 
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const STORE_APPOINTMENT = 'STORE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const DESTROY_APPOINTMENT = 'DESTROY_APPOINTMENT';

// Houses 
export const GET_HOUSES = 'GET_HOUSES';
export const STORE_HOUSE = 'STORE_HOUSE';
export const DESTROY_HOUSE = 'DESTROY_HOUSE';
export const UPDATE_HOUSE = 'UPDATE_HOUSE';
export const SET_SELECTED_HOUSE = 'SET_SELECTED_HOUSE';

// Services 
export const GET_SERVICES = 'GET_SERVICES';
export const STORE_SERVICE = 'STORE_SERVICE';
export const DESTROY_SERVICE = 'DESTROY_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';

// Service Types
export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';
export const STORE_SERVICE_TYPE = 'STORE_SERVICE_TYPE'; 
export const UPDATE_SERVICE_TYPE = 'UPDATE_SERVICE_TYPE'; 
export const DESTROY_SERVICE_TYPE = 'DESTROY_SERVICE_TYPE';

// Heating Types
export const GET_HEATING_TYPES = 'GET_HEATING_TYPES';
export const STORE_HEATING_TYPE = 'STORE_HEATING_TYPE'; 
export const UPDATE_HEATING_TYPE = 'UPDATE_HEATING_TYPE'; 
export const DESTROY_HEATING_TYPE = 'DESTROY_HEATING_TYPE';

// Building Types
export const GET_BUILDING_TYPES = 'GET_BUILDING_TYPES';
export const STORE_BUILDING_TYPE = 'STORE_BUILDING_TYPE'; 
export const UPDATE_BUILDING_TYPE = 'UPDATE_BUILDING_TYPE'; 
export const DESTROY_BUILDING_TYPE = 'DESTROY_BUILDING_TYPE';

// Accomodation Types
export const GET_ACCOMMODATION_TYPES = 'GET_ACCOMMODATION_TYPES';

// Users
export const GET_USERS = 'GET_USERS'; 
export const SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE'; 
export const SET_USERS_SEARCH_TERM = 'SET_USERS_SEARCH_TERM'; 

// Applications
export const STORE_APPLICATION = 'STORE_APPLICATION';

// Donate
export const DONATE = 'DONATE';

// Reports
export const GET_ACCOMODATION_TYPE_REPORT = 'GET_ACCOMODATION_TYPE_REPORT';

// Accomodation Sites
export const STORE_ACCOMMODATION_SITE = 'STORE_ACCOMMODATION_SITE';
export const GET_ACCOMMODATION_SITES = 'GET_ACCOMMODATION_SITES';
export const DESTROY_ACCOMMODATION_SITE = 'DESTROY_ACCOMMODATION_SITE';
export const UPDATE_ACCOMMODATION_SITE = 'UPDATE_ACCOMMODATION_SITE';
export const SET_SELECTED_ACCOMMODATION_SITE = 'SET_SELECTED_ACCOMMODATION_SITE';

// Misc
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';