import React, { Component } from 'react';
import { Card, List, ListItem, Input } from 'react-onsenui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UsersActions from '../../actions/users';
import { selectUsersBySearchTerm } from '../../reducers/users';
import User from './User';

class UserDirectory extends Component {

  componentDidMount() {
    const { actions } = this.props;

    actions.getUsers().then(response => console.log(response));
  }

  handleSearchTermChanged = e => {
    const { actions } = this.props;

    actions.setUsersSearchTerm(e.target.value);
  }
  
  renderRow = (user, i) => {
    // const { navigator } = this.props;
    const { pushPage } = this.props;

    return (
      <ListItem 
        key={i}
        modifier="divider"
        // onClick={() => pushPage({Component: User, props: {userId: user.id}})}
        onClick={() => pushPage(User, 'user', { userId: user.id })}
      >
        <p className="u-ml-05">{`${user.first_name} ${user.last_name}`}</p>
      </ListItem>
    );
  }

  render() {
    const { users, searchTerm } = this.props;

    return (
      <Card className="c-form">
        <h3>User Directory</h3>
        <div className="c-form__row u-mt-1">
          <Input
            onChange={this.handleSearchTermChanged}
            placeholder='Search'   
          />
        </div>

        {searchTerm != '' && (
          <List
            dataSource={users}
            renderRow={this.renderRow}
          />
        )}
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchTerm: state.users.searchTerm,
    users: selectUsersBySearchTerm(state)
  }
}

function mapDispatchToProps(dispatch) { 
  return {
    actions: bindActionCreators({...UsersActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDirectory);