import React from 'react';
import { Page, Card } from 'react-onsenui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderToolbar from '../utilities/RenderToolbar';

function UsefulLinks(props) {
  const { popPage } = props;

  return (
    <Page renderToolbar={() => <RenderToolbar title="Useful Links" defaultBackButton={true} backAction={popPage} />}>
      <Card>
        <h3>Tenancy</h3>

        <p><a href="https://www.tenancy.govt.nz/disputes/searching-tribunal-orders/" target="_blank">Tenancy Tribunal Check</a></p>
        <p><a href="https://www.centrix.co.nz/my-credit-score/" target="_blank">Centrix Credit Check</a></p>
        <p><a href="https://www.tenancy.govt.nz/" target="_blank">Tenancy Services</a></p>
        <p><a href="https://www.tpsportal.co.nz/auth/login" target="_blank">Tenancy Portal</a></p>
        
        <h3 className="u-mt-2">Property</h3>

        <p><a href="https://www.realestate.co.nz" target="_blank">Realestate.co.nz</a></p>
        <p><a href="https://www.professionals.co.nz" target="_blank">Professionals Rotorua</a></p>
        <p><a href="https://www.rotoruarentals.co.nz/" target="_blank">Rotorua Rentals</a></p>
        <p><a href="https://www.myrent.co.nz" target="_blank">Myrent.co.nz</a></p>
        <p><a href="https://www.harcourtsrotorua.co.nz" target="_blank">Harcourts</a></p>
        <p><a href="https://www.evesrentals.co.nz" target="_blank">Eves</a></p>
        <p><a href="https://www.tremainsrentals.co.nz" target="_blank">Tremains</a></p>
        <p><a href="https://www.centralcityrentals.co.nz/" target="_blank">City Central</a></p>
        <p><a href="https://precisionproperty.co.nz/index.php/listings" target="_blank">Precision</a></p>
        <p><a href="https://www.trademe.co.nz/a/property/residential/rent/bay-of-plenty/rotorua" target="_blank">tyTrade Me</a></p>
        <p><a href="https://www.therentshop.co.nz/listings" target="_blank">The Rent Shop</a></p>
        <p><a href="https://www.inspirerealestate.co.nz/rental-listings/" target="_blank">Inspire</a></p>
      </Card>
    </Page>
  );
}

function mapStateToProps(state) {  
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UsefulLinks)