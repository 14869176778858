import { GET_BUILDING_TYPES, STORE_BUILDING_TYPE, UPDATE_BUILDING_TYPE, DESTROY_BUILDING_TYPE } from '../actionTypes';

const initialState = []

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_BUILDING_TYPES:
      return getBuildingTypes(payload);
    case STORE_BUILDING_TYPE:
      return storeBuildingType(state, payload);
    case UPDATE_BUILDING_TYPE:
      return updateBuildingType(state, payload);
    case DESTROY_BUILDING_TYPE:
      return destroyBuildingType(state, payload);
    default:
      return state;
    }
  }
  
  function getBuildingTypes(payload) {
    return [
      ...payload.data,
    ];
  }
  
  function storeBuildingType(state, payload) {
    return [
      ...state,
      payload.data
    ]
  }
  
  function updateBuildingType(state, payload) {
    return [
      ...state.filter(s => s.id !== payload.data.id),
      payload.data
    ]
  }
  
  function destroyBuildingType(state, payload) {
    return [
      ...state.filter(s => s.id !== payload.data.id)
    ]
  }
  
  
  export default reducer;