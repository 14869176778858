import React from 'react';
import { Page, Card } from 'react-onsenui';
import { reduxForm, Form, Field } from 'redux-form';
import RenderToolbar from '../utilities/RenderToolbar';
import SubmitButton from '../utilities/SubmitButton';
import Loading from '../utilities/Loading';
import { renderField } from '../RenderField';
import { UPDATE_BUILDING_TYPE } from '../../actionTypes';
import cogoToast from 'cogo-toast';


function BuildingTypeForm({ handleSubmit, submitting, formAction, navigator }) {

  const handleFormSubmit = values => {
    return formAction(values).then(response => {
      console.log(response);

      let successMessage = 'Building type added';
      if (response.type === UPDATE_BUILDING_TYPE) {
        successMessage = 'Building type updated';
      }

      cogoToast.success(successMessage);

      navigator.popPage();
    });
  }

  const renderToolbar = () => <RenderToolbar title="Building Type" defaultBackButton={true}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Form className="c-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Card>
          <p>Name</p>

          <div className="c-form__row">
            <Field
              id="name"
              name="name"
              type="text"
              placeholder="Building type name"
              component={renderField} 
            />
          </div>
        </Card>
        {submitting 
          ? <Loading />
          : <SubmitButton>Save</SubmitButton>}
      </Form>
    </Page>
  );
}

export default reduxForm({
  form: 'BuildingTypeForm'
})(BuildingTypeForm);