import React from 'react';
import { Button, Row, Col, Icon } from 'react-onsenui';

export default function FormDocument(props) {
  const { onRemoveDocumentClicked, document, newDocuments } = props;

  return (
    <Row className="u-mt-1">
      <Col width="60%">
        <p className="u-mt-05">
          <Icon 
            icon="ion-ios-document" 
            className={newDocuments && newDocuments.includes(document) ? 'u-text--green u-mr-05' : 'u-mr-05'}
            size={{default: 26, material: 26}}
          />
          <a href={`https://st-wact.s3.amazonaws.com/${document}`}>{document}</a>
        </p>
      </Col>
      <Col width="40%">
        <Button 
          onClick={onRemoveDocumentClicked} 
          className={"button--large--cta u-bg--red"}
        >
          Remove
        </Button>
      </Col>
    </Row>
  );
}