import { DESTROY_ACCOMMODATION_SITE, GET_ACCOMMODATION_SITES, STORE_ACCOMMODATION_SITE, UPDATE_ACCOMMODATION_SITE, SET_SELECTED_ACCOMMODATION_SITE } from '../actionTypes';
import { createSelector } from 'reselect';

const initialState = {
  items: [],
  selectedAccommodationSiteId: null
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_ACCOMMODATION_SITES:
      return getAccomodationSites(state, payload);
    case STORE_ACCOMMODATION_SITE:
      return storeAccomodationSite(state, payload);
    case DESTROY_ACCOMMODATION_SITE:
      return destroyAccomodationSite(state, payload);
    case UPDATE_ACCOMMODATION_SITE:
      return updateAccomodationSite(state, payload)
    case SET_SELECTED_ACCOMMODATION_SITE:
      return setSelectedAccomodationSite(state, payload)
    default:
      return state;
  }
}

function getAccomodationSites(state,payload) {
  return {
    ...state,
    items: payload.data
  };
}

function storeAccomodationSite(state, payload) {
  return { 
    ...state,
    items: [
      ...state.items,
      payload.data,
    ]
  }
}

function destroyAccomodationSite(state, payload) {
  return {
      ...state,
      items: [
      ...state.items.filter(item => item.id !== payload.data.id)
    ]
  }
}

function updateAccomodationSite(state, payload) {
  return {
    ...state,
    items: [ 
      ...state.items.map(item => item.id == payload.data.id ? payload.data : item)
    ]
  }
}

function setSelectedAccomodationSite(state, payload) {
  return {
    ...state,
    selectedAccommodationSiteId: payload
  }
}

export const selectAccomodationSites = state => state.accommodationSites.items;
export const selectAccommodationSiteId = state => state.accommodationSites.selectedAccommodationSiteId;

export const selectAccommodationSite = createSelector(
  [selectAccomodationSites, selectAccommodationSiteId], 
  (accomodationSites, accommodationSiteId) => {
    if (accommodationSiteId) {
      return accomodationSites.find(a => a.id === accommodationSiteId);
    }
  }
);


export default reducer;