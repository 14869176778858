import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import { ProgressCircular } from 'react-onsenui';
import ons from 'onsenui';
import SubmitButton from '../utilities/SubmitButton';

const LoginForm = props => {
  const { handleSubmit, submitting, error } = props;
  const isAndroid = ons.platform.isAndroid();

  return (
    <Form onSubmit={handleSubmit} className="c-form">
      <div className="c-form__row">
        <Field 
          name="email"
          id="email" 
          component={renderField} 
          type="text" 
          placeholder="Email"
          validate={[Validators.required, Validators.email]}
        />
      </div>
     
      <div className="c-form__row">
        <Field
          name="password" 
          id="password" 
          component={renderField} 
          type="password"
          placeholder="Password"
          validate={Validators.required}
        />
      </div>

      {error && (
        <div className="c-form__row">
          <p className="c-form__error u-mt-0">
            Your email address or password are incorrect
          </p>
        </div>
      )}

      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        {submitting 
          ? <ProgressCircular indeterminate />
          : <SubmitButton>Login</SubmitButton>
        }
      </div>
    </Form>
  )
}

export default reduxForm({
  form: 'LoginForm'
})(LoginForm)