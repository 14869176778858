import { GET_SERVICE_TYPES, STORE_SERVICE_TYPE, UPDATE_SERVICE_TYPE, DESTROY_SERVICE_TYPE } from '../actionTypes';

const initialState = []

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case GET_SERVICE_TYPES:
      return getServiceTypes(payload);
    case STORE_SERVICE_TYPE:
      return storeServiceType(state, payload);
    case UPDATE_SERVICE_TYPE:
      return updateServiceType(state, payload);
    case DESTROY_SERVICE_TYPE:
      return destroyServiceType(state, payload);
    default:
      return state;
  }
}

function getServiceTypes(payload) {
  return [
    ...payload.data,
  ];
}

function storeServiceType(state, payload) {
  return [
    ...state,
    payload.data
  ]
}

function updateServiceType(state, payload) {
  return [
    ...state.filter(s => s.id !== payload.data.id),
    payload.data
  ]
}

function destroyServiceType(state, payload) {
  return [
    ...state.filter(s => s.id !== payload.data.id)
  ]
}

export default reducer;