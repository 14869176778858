import React from 'react';
import { Page, Card, Button, Col, Row, Icon } from 'react-onsenui';
import RenderToolbar from '../utilities/RenderToolbar';
import AccommodationSitesForm from './AccommodationSitesForm';
import { bindActionCreators } from 'redux';
import * as AccommodationSiteActions from "../../actions/accommodationSite";
import { connect } from 'react-redux';
import { selectAccommodationSite } from '../../reducers/accommodationSites';
import { url } from '../../utils/s3';


function AccommodationSite(props) {
  const { pushPage, popPage, actions, isAdmin, accommodationSite } = props;
  
  const handleEditAccommodationSiteClicked = () => {
    const props = { 
      formAction: actions.updateAccommodationSite,
      title: 'Edit Accommodation Site',
      buttonText: 'Save Changes',
      initialValues: { 
        ...accommodationSite, 
        documents: accommodationSite.documents.map(d => d.file) 
      },
    }

    pushPage(AccommodationSitesForm, 'AccommodationSitesForm', props);
  }

  const handleDeleteAccommodationSiteClicked = () => {
    actions.destroyAccommodationSite(accommodationSite.id).then(response => {
      console.log(response);
    
      popPage();
    });
  }

  return (
    <Page renderToolbar={() => <RenderToolbar backAction={popPage} title={accommodationSite && accommodationSite.name}/>}>
      {accommodationSite && (
        <Card>
          <div className="c-accommodation-site">
            <Row>
              <h3>{accommodationSite.name}</h3>
            </Row>
            
            <Row>
              <span>{accommodationSite.address}</span>
            </Row>

            {accommodationSite.info && (
              <>
                <h4 className="u-mt-1">Information</h4>
                <span>{accommodationSite.info}</span>
              </>
            )}
            
            {accommodationSite.documents.length > 0 && (
              <Row>
                <h4 className="u-mt-1">Documents</h4>
                
                {accommodationSite.documents.map(document => (
                  <div className="c-accommodation-site__document u-mb-05">
                    <Icon 
                      icon="ion-ios-document" 
                      className="u-mr-05"
                      size={{default: 26, material: 26}}
                    />
                    <a href={url(document.file)} target="_blank" rel="noopener noreferrer">{document.file}</a>
                  </div>
                ))}
              </Row>
            )}
          </div>
        </Card>
      )}

      {isAdmin && (
        <Row className="u-mt-1">
          <Col className="u-mr-05">
            <Button className="button--large--cta" onClick={handleEditAccommodationSiteClicked}>Edit</Button>
          </Col>
          <Col className="u-ml-05">
            <Button className="button--large--cta u-bg--red" onClick={handleDeleteAccommodationSiteClicked}>Delete</Button>
          </Col>
        </Row>
      )}
    </Page>
  )
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.admin,
    accommodationSite: selectAccommodationSite(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...AccommodationSiteActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationSite)