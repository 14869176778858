import * as api from '../api/api';
import ajaxCallError from './ajaxCallError'; 
import { GET_ACCOMODATION_TYPE_REPORT } from '../actionTypes';

export function getAccomodationTypeReport() {
  return function(dispatch) {
    return api
      .getAccomodationTypeReport()
      .then(
        response => dispatch(getAccomodationTypeReportSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

export function getAccomodationTypeReportSuccess(response) {
  return {
    type: GET_ACCOMODATION_TYPE_REPORT,
    payload: response.data || {}
  }
}