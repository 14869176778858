import React, {Component} from 'react';
import Login from './login/Login';
import MasterDetail from './MasterDetail';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AccommodationTypeActions from '../actions/accommodationType';
import * as AccommodationSiteActions from '../actions/accommodationSite';
import ResetPasswordConfirm from './login/ResetPasswordConfirm';


class Main extends Component {
  componentDidMount() {
    const { actions } = this.props;

    actions.getAccommodationTypes().then(response => {
      console.log(response);
    });

    actions.getAccommodationSites().then(response => {
      console.log(response);
    });
  }
  
  render () {
    const { isAuthenticated, navigator } = this.props;
  
    return isAuthenticated 
      ? <MasterDetail navigator={navigator}/> 
      : window.location.pathname.includes('password/reset') 
        ? <ResetPasswordConfirm navigator={navigator}/>
        : <Login navigator={navigator}/>;
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.loggedIn
  }
}

function mapDispatchToProps(dispatch) {
  return  {
    actions: bindActionCreators({ ...AccommodationTypeActions, ...AccommodationSiteActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)