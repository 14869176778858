import React from 'react';
import { Card } from 'react-onsenui';

export default function ListingItem(props) {
  
  const { image, title, subtitle, onClick, icons } = props;

  return (
    <div className="c-list-Item" onClick={onClick}>
      <Card>
        {image && <img className="c-list-item__image" src={`https://st-wact.s3.amazonaws.com/${image}`}/>}
        <div className="c-list-item__content">
          <div>
            <p className="c-list-item__content__title">{title}</p>
            <p className="c-list-item__content__subtitle">{subtitle}</p>
          </div>
          <div className="c-list-item__content__icons">
            {icons && icons.length > 0 && icons.map((item, i) => (
              <div key={i} className="c-list-item__content__icons__icon">
                <item.icon />
                <p>{item.amount}</p>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  )
}