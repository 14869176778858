import React from 'react';
import { Page, Card, Col, Row, Button } from 'react-onsenui';
import RenderToolbar from '../utilities/RenderToolbar';
import CardImage from '../CardImage';
import ServiceForm from './ServiceForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServiceActions from '../../actions/services';
import { selectService } from '../../reducers/services';


function ServiceListing(props) {
  const { service, popPage, actions, isAdmin, pushPage } = props;

  const handleEditServiceClicked = () => {
    const props = { 
      initialValues: { ...service },
      formAction: actions.updateService,
      title: 'Edit Service',
      buttonText: 'Save Changes'
    }

    pushPage(ServiceForm, 'ServiceForm', props);
  }

  const handleDeleteServiceClicked = () => {
    actions.destroyServiceById(service.id).then(response => {
      console.log(response)
    
      popPage();
    });
  }

  return (
    <Page renderToolbar={() => <RenderToolbar backAction={popPage} title={service && service.name}/>}>
      {service && (
        <Card>
          {service.photo && <CardImage image={service.photo}/>}
          <div className="c-listing">
            <p className="c-listing__subheading">Name</p>
            <p className="c-listing__item">{service.name}</p><br />
            
            <p className="c-listing__subheading">Service Types</p>
            {service.service_types.map(st =>              
              <p key={`service_type_${st.id}`} className="c-listing__item">{st.name}</p>
            )}
            <br />
            
            <p className="c-listing__subheading">Description</p>
            <p className="c-listing__item">{service.description}</p><br />
            
            {service.email ?
              <>
                <p className="c-listing__subheading">Email</p>
                <p className="c-listing__item"><a href={`mailto:${service.email}`}>{service.email}</a></p>
              </>
            : null}
            
            <p className="c-listing__subheading">Phone</p>
            <p className="c-listing__item"><a href={`tel:${service.phone}`}>{service.phone}</a></p>
          </div>
        </Card>
      )}

      {isAdmin ? (
        <Row className="u-mt-1">
          <Col className="u-mr-05">
            <Button 
              className="button--large--cta" 
              onClick={handleEditServiceClicked}
            >
              Edit
            </Button>
          </Col>
          <Col className="u-ml-05">
            <Button 
              className="button--large--cta u-bg--red" 
              onClick={handleDeleteServiceClicked}
            >
              Delete
            </Button>
          </Col>
        </Row>
      ) : (
        <></>//<Button className="button--large--cta" onClick={handleSubmitClicked}>Submit an application</Button>
      )}
    </Page>
  )
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.admin,
    service: selectService(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ServiceActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceListing);