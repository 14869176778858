import React from 'react';
import { Field } from 'redux-form';
import { renderField } from '../../RenderField';
import * as Validators from '../../Validators';

export function StepFive(props) {
  const { pwdVal, confPwdVal, errors } = props;

  return (
    <div>
      <h2>Create a password</h2>
      <p>Choose a password 8 characters or greater</p>
      <div className="c-form__row">
        <Field 
          name="password"
          id="password" 
          component={renderField} 
          type="password" 
          placeholder="Password"
          validate={[Validators.required, Validators.password]}
        />
        {errors.password !== undefined && <div className="c-form__error"><i>{errors.password[0]}</i></div>}
      </div>

      <p>Re-enter the password you have chosen</p>
       <div className="c-form__row">
        <Field 
          name="confirm_password"
          id="confirm_password" 
          component={renderField} 
          type="password" 
          placeholder="Confirm Password"
          validate={[Validators.required, Validators.password]}
        />
        {((pwdVal && confPwdVal) && (pwdVal !== confPwdVal)) && <div className="c-form__error"><i>These passwords do not match</i></div>}
      </div>
    </div>
  );
}