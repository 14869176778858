import React, { Component } from 'react';
import { Page, ProgressCircular, List, ListItem, Button, Card, Row, Col } from 'react-onsenui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppointmentForm from './AppointmentForm';
import AppointmentAvailabilitiesForm from './AppointmentAvailabilityForm';
import * as UsersActions from "../../actions/users";
import * as AppoinmentActions from "../../actions/appointment";
import moment from 'moment';

class Appointments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDateString: '',
      selectedAppointments: [],
      isOpen: false,
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getUsers().then(response => console.log(response));
    actions.getAppointments().then(response => console.log(response));
  }

  handleSetAppointmentAvaliabiltyClicked = () => {
    const { pushPage } = this.props;

    pushPage(AppointmentAvailabilitiesForm, 'appointment-slot-form');
  }

  handleBookAppointmentClicked = () => {
    const { pushPage } = this.props;

    pushPage(AppointmentForm, 'appointment-form');
  }

  renderRow = (item, i) => { 
    const { isBookable } = this.props;

    const date = item.date;
    const staffMember = item.staff_member;
    const user = item.user;

    return (
      <ListItem
        key={i}
        modifier="nodivider"
      >
        <Card className="u-mb-0">
          <Row>
            <Col>
              <span className="c-appointments__name u-mb-05">
                {isBookable 
                  ? `${user.first_name} ${user.last_name}` 
                  : `${staffMember.first_name} ${staffMember.last_name}`
                }
              </span>
              <br></br>
              <span className="c-appointments__details">{item.details}</span>
            </Col>
            <Col className="c-appointments__date">
              <span>{moment(date).format('MMMM Do')}</span>
              <br></br>
              <span>{moment(date).format('h:mm a')}</span>
            </Col>
          </Row>
        </Card>
      </ListItem>
    );
  }

  render() {
    const { isAdmin, isBookable, appointments } = this.props;

    return (
      <Page className="c-appointments">
        {isAdmin && isBookable && (
          <Button
            className="button--large--cta" 
            onClick={this.handleSetAppointmentAvaliabiltyClicked}
          >
            Set My Availablility
          </Button>
        )}

        {!isAdmin && !isBookable && (
          <Button
            className="button--large--cta" 
            onClick={this.handleBookAppointmentClicked}
          >
            Book an Appointment
          </Button>
        )}

        {appointments ? (
          appointments.length > 0 ? (
            <List
              dataSource={appointments}
              renderRow={this.renderRow}
            />
          ) : (
            <Card className="u-mt-1">
              <p>No appointments</p>
            </Card>
          )
        ) : (
          <div className="c-appointments__loading u-text-center">
            <ProgressCircular indeterminate />
          </div>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  let isAdmin = state.user.admin;
  let isBookable = state.user.bookable;
  let appointments = isBookable ? state.user.staff_appointments : state.user.appointments;
  
  return {
    isAdmin,
    isBookable,
    appointments
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UsersActions, ...AppoinmentActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
