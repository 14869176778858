import React, { Component } from 'react';
import { Page, Card, ProgressCircular } from "react-onsenui";
import { connect } from "react-redux";
import UserDirectory from './users/UserDirectory';


class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    }
  }

  render() {
    const { user, navigator, pushPage } = this.props

    if (user.admin) {
      return (
        <Page>
          <UserDirectory pushPage={pushPage} navigator={navigator}/>
        </Page>
      )
    }

    return (
      <Page>
        {user.email ? (
          <>
            <Card>
              <h3>Welcome {user.first_name}!</h3>

              <p>We've put together the following videos to help you succeed in your tenancy journey.</p>

              <h4 className="u-mt-2">Introduction to WACT</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Introduction-to-WACT.mp4" width="100%" controls />

              <h4 className="u-mt-2">Standard House Requirements</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Standard-Housing-Requirements.mp4" width="100%" controls />

              <h4 className="u-mt-2">Applications Tenancy Portals</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Applications-Tenancy-Portals.mp4" width="100%" controls />

              <h4 className="u-mt-2">Viewings</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Viewings.mp4" width="100%" controls />

              <h4 className="u-mt-2">Tenancy Agreements</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Tenancy-Agreements.mp4" width="100%" controls />

              <h4 className="u-mt-2">Rights & Responsibilities</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Rights-&-Responsibilities.mp4" width="100%" controls />
              
              <h4 className="u-mt-2">Post Placement Support</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Post-Placement-Support.mp4" width="100%" controls />
              
              <h4 className="u-mt-2">Inspections</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Inspections.mp4" width="100%" controls />

              <h4 className="u-mt-2">Ending Tenancy</h4>
              <video src="https://st-wact.s3.us-east-1.amazonaws.com/videos/Ending-Tenancy.mp4" width="100%" controls />
            </Card>
          </>
        ) : (
          <div className="page-loading">
            <ProgressCircular indeterminate />
          </div>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Home);
