import React from 'react';
import { Field } from 'redux-form';
import { renderField } from '../../RenderField';
import * as Validators from '../../Validators';

export function StepOne({errors}) {
  return (
    <div>
      <h2>Tell us about yourself</h2>
      <p>What is your first name?</p>
      <div className="c-form__row">
        <Field 
          name="first_name"
          id="first_name" 
          component={renderField} 
          type="text" 
          placeholder="First Name"
          validate={[Validators.required]}
        />
        {errors.first_name !== undefined && <div className="c-form__error"><i>{errors.first_name[0]}</i></div>}
      </div>

      <p>What is your last name?</p>
      <div className="c-form__row">
        <Field 
          name="last_name"
          id="last_name" 
          component={renderField} 
          type="text" 
          placeholder="Last Name"
          validate={[Validators.required]}
        />
        {errors.last_name !== undefined && <div className="c-form__error"><i>{errors.last_name[0]}</i></div>}
      </div>
    </div>
  );
}