import React, { useState, useEffect } from 'react';
import { reduxForm, formValueSelector, Form, change, Field } from 'redux-form';
import { renderField } from './RenderField';
import * as Validators from './Validators';
import ons from 'onsenui';
import { ProgressCircular, Page, Card } from 'react-onsenui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ApplicationActions from '../actions/application';
import RenderToolbar from './utilities/RenderToolbar';


function ApplicationForm(props) {
  const { otherOccupants, actions, user, house, handleSubmit, popPage } = props;
  const [submitting, setSubmitting] = useState(false);

  const isAndroid = ons.platform.isAndroid();

  const handleFormSubmit = handleSubmit(values => {
    const data = {
      ...values,
      user_id: user.id,
      house_id: house.id,
    }
    
    actions.storeApplication(data).then(response => {
      console.log(response);

      if (response.payload.message === 'success') {
        popPage();
      }

    });
  });

  return (
    <Page renderToolbar={() => <RenderToolbar title="New Application" backAction={popPage} />}>
      <Form className="c-form" onSubmit={handleFormSubmit}>
        <Card>
          <p>What date could you move in?</p>
          <div className="c-form__row">
            <Field 
              id="move_date"
              name="move_date"
              component={renderField}
              validate={Validators.required}
              placeholder="Date"
              type="date"
            />
          </div>

          <p>Is someone moving with you?</p>
          <div className="c-form__row">
            <Field 
              id="occupant_type_id"
              name="occupant_type_id"
              component={renderField}
              validate={Validators.required}
              placeholder="Please Select"
              type="select"
              options={[
                { id: 1, name: 'Just Me' },
                { id: 2, name: 'Me and a freind' },
                { id: 3, name: 'Me and my partner' },
                { id: 4, name: 'Me and my family' }
              ]}
            />
          </div>

          {otherOccupants && otherOccupants != 1 && (
            <>
              <p>How many total occupants will be moving in?</p>
              <div className="c-form__row">
                <Field 
                  id="total_occupants"
                  name="total_occupants"
                  component={renderField}
                  validate={Validators.required}
                  placeholder="Total occupants"
                  type="number"
                />
              </div>
            </>
          )}         
        </Card>

        {submitting ? (
            <ProgressCircular indeterminate/>
          ) : (
            <button 
              type="submit" 
              className={isAndroid ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'} 
              disabled={submitting}
            >
              Save
            </button>
          )}
      </Form>
    </Page>
  )
}

ApplicationForm = reduxForm({
  form: 'ApplicationForm'
})(ApplicationForm)

function mapStateToProps(state) {  
  const selector = formValueSelector('ApplicationForm');

  return {
    user: state.user,
    otherOccupants: selector(state, 'occupant_type_id') 
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...ApplicationActions, change}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm)