import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Page, List, ProgressCircular } from 'react-onsenui';
import ServiceForm from './ServiceForm';
import { bindActionCreators } from 'redux';
import * as ServiceActions from '../../actions/services';
import * as ServiceTypeActions from '../../actions/serviceType';
import ListingItem from '../ListingItem';
import ServiceListing from './ServiceListing';


class Services extends Component {

  componentDidMount() {
    const { actions } = this.props;

    actions.getServices().then(response => console.log(response));
    actions.getServiceTypes().then(response => console.log(response));
  }

  handleViewServiceClicked = service => {
    const { actions, pushPage } = this.props;
    
    actions.setSelectedService(service.id);

    pushPage(ServiceListing, service.id, { serviceId: service.id })
  }

  handleAddServiceClick = () => {
    const { pushPage, actions } = this.props;

    const props = {
      title: 'Add Service',
      buttonText: 'Add This Listing',
      formAction: actions.storeService
    }

    pushPage(ServiceForm, 'ServiceForm', props);
  }

  renderRow = (row, i) => (
    <ListingItem 
      key={i}
      image={row.photo}
      title={row.name}
      subtitle={row.service_types.map(st => st.name).join(', ')}
      onClick={() => this.handleViewServiceClicked(row)}
      modifier="nodivider"
    />
  )
  
  render() {
    const { services, isAdmin } = this.props;

    return (
      services ? (
        <Page>
          {isAdmin && (
            <Button 
              className="button--large--cta u-mb-1" 
              onClick={this.handleAddServiceClick}
            >
              Add Service Listing
            </Button>
          )}
          <List 
            dataSource={services}
            renderRow={this.renderRow}
          />
        </Page>
      ) : (
        <div className="page-loading">
          <ProgressCircular indeterminate />
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.admin,
    services: state.services.items
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ServiceActions, ...ServiceTypeActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)