import React from 'react';
import { Page, Card, List, ListItem, Button, Icon } from 'react-onsenui';
import RenderToolbar from '../utilities/RenderToolbar';
import { connect } from 'react-redux';
import BuildingTypeForm from '../building-types/BuildingTypeForm';
import { bindActionCreators } from 'redux';
import * as BuildingTypeActions from '../../actions/buildingType';


function BuildingTypes({ buildingTypes, navigator, actions }) {

  const handleAddBuildingTypeClicked = () => {
    navigator.pushPage({ Component: BuildingTypeForm }, {
      props: {
        formAction: actions.storeBuildingType
      }
    });
  }

  const handleBuildingTypeClicked = buildingType => {
    navigator.pushPage({ 
      Component: BuildingTypeForm,
      props: { 
        initialValues: buildingType,
        formAction: actions.updateBuildingType
      }
    });
  }

  const handleDeleteClicked = id => {
    const response = window.confirm('Are you sure you want to delete this building type?');
    if (response) {
      actions.destroyBuildingType(id).then(response => {
        console.log(response);
      });
    }
  }

  const renderRow = (buildingType, i) => (
    <ListItem key={i}> 
      <div className="u-flex-row-between">
        <span>{buildingType.name}</span>
      
        <div>
          <Button className="u-mr-1" onClick={() => handleBuildingTypeClicked(buildingType)}>
            <Icon icon="edit"/>
          </Button>

          <Button onClick={() => handleDeleteClicked(buildingType.id)}>
            <Icon icon="trash"/>
          </Button>
        </div>
      </div>
    </ListItem>
  );

  const renderToolbar = () => <RenderToolbar title="Building Types" defaultBackButton={true}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <Button 
        onClick={handleAddBuildingTypeClicked}
        className="button--large--cta u-mb-1"
      >
        Add Building Type
      </Button>

      <Card>
        <h3>Building Types</h3>
        
        <List
          dataSource={buildingTypes}
          renderRow={renderRow}
        />
      </Card>
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    buildingTypes: state.buildingTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...BuildingTypeActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingTypes);
